export const creditCards = {
  VISA: 'VISA',
  MASTER: 'MASTER',
  AMEX: 'AMEX',
  ELO: 'ELO',
  DINERS: 'DINERS',
  JCB: 'JCB',
  AURA: 'AURA',
  DISCOVER: 'DISCOVER',
  HYPERCARD: 'HYPERCARD',
};

const cardBrandArray = [
  {
    id: '',
    value: '',
    regex: null,
    icon: '',
  },
  {
    id: '1',
    value: creditCards.VISA,
    regex: /^(0000000000000001)|4\d{0,}?$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/visa.svg',
  },
  {
    id: '2',
    value: creditCards.MASTER,
    regex:
      /^(5[1-5]\d{4}|22[2-9][1-9]\d{2}|2[3-6]\d{3}|27[0-1]\d{3}|2720\d{2})\d{0,}$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/mastercard.svg',
  },
  {
    id: '3',
    value: creditCards.AMEX,
    regex: /^3[47]\d{0,}$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/american-express.svg',
  },
  {
    id: '4',
    value: creditCards.ELO,
    regex:
      /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,})|((5067)|(4576)|(4011))\d{0,})$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/elo.svg',
  },
  {
    id: '5',
    value: creditCards.DINERS,
    regex: /^3(0[0-5]|[68]\d)\d{0,}$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/diners.svg',
  },
  {
    id: '6',
    value: creditCards.JCB,
    regex: /^(?:2131|1800|35\d{3})\d{0,}$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/jcb.svg',
  },
  {
    id: '7',
    value: creditCards.AURA,
    regex: /^(5078\d{2})\d{0,}$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/aura.svg',
  },
  {
    id: '8',
    value: creditCards.DISCOVER,
    regex: /^6(?:011|5[0-9]{2})[0-9]{0,}$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/discover.svg',
  },
  {
    id: '9',
    value: creditCards.HYPERCARD,
    regex: /^(606282\d{0,})|(3841\d{0,})$/,
    icon: 'https://downloadserver-cdn.nelogica.com.br/content/finance/credit-card/hipercard.svg',
  },
];
export const getCardBrandByValue = (value) => {
  const brand = cardBrandArray.find((el) => {
    return el.value === value?.toUpperCase().trim();
  });
  if (brand) {
    return {
      id: brand.id,
      value: brand.value,
      icon: brand.icon,
    };
  }
  return brand;
};
export const getCardBrand = (cardNumber) => {
  const ccNumber = cardNumber.replace(/ /g, '');

  const brand = cardBrandArray.filter(cardBrand => ccNumber.match(cardBrand.regex)).pop();
  if (brand) {
    return {
      id: brand.id,
      value: brand.value,
      icon: brand.icon,
    };
  }
  return brand;
};

export const luhnValidation = (rawCreditCardNumber) => {
  const creditCardNumberWithoutSpaces = rawCreditCardNumber.replace(/\s+/g, '');

  if (creditCardNumberWithoutSpaces.length !== 14 && creditCardNumberWithoutSpaces.length !== 15 && creditCardNumberWithoutSpaces.length !== 16) {
    return false;
  }

  let validationSum = 0;

  for (let i = 0; i < creditCardNumberWithoutSpaces.length; i++) {
    let currentDigit = parseInt(creditCardNumberWithoutSpaces[i], 10);

    if ((creditCardNumberWithoutSpaces.length - i) % 2 === 0) {
      currentDigit *= 2;
      if (currentDigit > 9) {
        currentDigit -= 9;
      }
    }

    validationSum += currentDigit;
  }

  return validationSum % 10 === 0;
};

export const luhnCheck = (num) => {
  let arr = (num + '')
    .split('')
    .reverse()
    .map(x => parseInt(x));
  let lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce(
    (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9),
    0,
  );
  sum += lastDigit;
  return sum % 10 === 0;
};
export const getErrorMessage = (response) => {
  let strMsg = 'checkoutErrorList.genericError';
  if (response && response.message) {
    if (response.message.includes('Card Canceled')) {
      strMsg = 'checkoutErrorList.creditCardCanceled';
    } else if (response.message.includes('Time Out')) {
      strMsg = 'checkoutErrorList.creditCardTimeOut';
    } else if (response.message.includes(' Not Authorized')) {
      strMsg = 'checkoutErrorList.creditCardNotAuthorized';
    } else if (response.message.includes('Expired Card')) {
      strMsg = 'checkoutErrorList.creditCardExpiredCard';
    } else if (response.message.includes('Locked Card')) {
      strMsg = 'checkoutErrorList.creditCardLockedCard';
    } else if (response.message.includes('Problems with the Credit Card')) {
      strMsg = 'checkoutErrorList.creditCardProblems';
    } else if (response.message === 'Not Authorized') {
      strMsg = 'checkoutErrorList.creditCardError';
    }
  }
  return strMsg;
};
export default creditCards;
