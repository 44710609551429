import React, { useState } from 'react';
import { Container, TextContainer, DiscountBadge, OfferLink, CloseIcon, SecondColumn } from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UserHelper from '@/helpers/UserHelper';

function FloatingBar() {
  const { t } = useTranslation('translations');
  const [ isOpened, setIsOpened ] = useState(true);
  const { isLoggedIn } = useSelector(state => state.auth);
  const token = UserHelper.getBearerToken();
  const tokenParam = isLoggedIn ? `?token=${token}` : '';
  const url = `https://store.nelogica.com.br/academy/trader-week${tokenParam}`;

  return (
    <>
      {isOpened
      && (
        <Container>
          <TextContainer>
            <picture>
              <source srcSet="/assets/images/trader-week/trader-week-mobile.png" media="(max-width: 769px)" />
              <img src="/assets/images/trader-week/trader-week.png" alt="Trader Week" />
            </picture>
            <SecondColumn>
              <DiscountBadge>
                <p>
                  <span className="primary-text">{ t('traderWeek.enjoy') }</span> <span>{ t('traderWeek.max') }</span> <img src="/assets/images/trader-week/discount-badge.png" alt="60%" /><span>OFF</span>
                </p>
              </DiscountBadge>
              <OfferLink onClick={() => window.open(url, 'blank')}>{ t('traderWeek.seeOffers') }</OfferLink>
            </SecondColumn>
          </TextContainer>
          <CloseIcon onClick={() => setIsOpened(false)} />
        </Container>
      )}
    </>
  );
}

export default FloatingBar;
