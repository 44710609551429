import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 205px;
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, #031520 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    backdrop-filter: blur(12px);
    background-image: url('/assets/images/trader-week/bg-image.png');
    background-size: auto 100%;
    opacity: .15;
  }
  &:after {
    top: auto;
    bottom: 0;
  }
  .glow {
    position: absolute;
    left: 0;
    bottom: -75px;
    width: 100%;
    height: 100%;
    background: radial-gradient(12.87% 35.52% at 50% 64.48%, rgba(201, 231, 81, 0.2) 0%, rgba(201, 231, 81, 0.1) 42.86%, rgba(201, 231, 81, 0.01) 86.2%, rgba(201, 231, 81, 0) 100%);
    z-index: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 373px;
    background: linear-gradient(180deg, #000000 0%, #031520 100%);
    img {
      max-width: 238px;
    }
    .glow {
      display: none;
    }
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 932px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: relative;
  z-index: 100;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #C9E751;
    text-align: center;
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #FFFFFF;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #B1B9BE;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    h2 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 20px;
    }
    p {
      display: none;
    }
  }
`;
