import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import SvgIcon from '../../../handlers/HandleSvg';
import {
  FormGroup,
  Label,
  Input,
  Required,
  FeedbackStatus,
  InputContainer,
  Loader,
  ContainerIcon,
  Password,
  BrandCardImage,
  Cep,
  LabelDescription,
} from './styles';
import { generateMaskedText } from '../../../validations/Mask/masks';
import Svg from '../../../handlers/HandleSvg';
import { product } from '../../../styles/theme/themes';
const isInvestMe = product === 'INVESTMEB3';
const TextInput = ({
  type = 'text',
  name,
  label,
  maxLength,
  placeholder,
  required,
  hasError,
  errorMessage,
  status,
  statusMessage,
  onChange,
  pattern,
  onBlur,
  wrapperStyle = {},
  actionButton = false,
  inputStyle = {},
  labelStyle = {},
  mask = undefined,
  brand = '',
  brandIcon,
  disabled = false,
  clear = false,
  clearAction = null,
  isLogin = false,
  value,
  loading = false,
  checked = false,
  isPassword = false,
  autoComplete = '',
  labelDescription = '',
  passIconColor = null,
  autofocus = false,
  isBrasilianUser = true,
}) => {
  const [ _type, setType ] = useState('');
  const [ isPasswordVisible, setIsPasswordVisible ] = useState(true);
  const themeContext = useContext(ThemeContext);
  const successColor = themeContext.color.feedback.success;
  const errorColor = themeContext.color.feedback.danger;

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setType(isPasswordVisible ? 'text' : 'password');
  };
  useEffect(() => {
    if (hasError) {
      document.getElementById(name).focus();
    }
  }, [ hasError ]);
  const { t } = useTranslation('translations');

  const handleChange = useCallback((e) => {
    if (mask !== undefined) generateMaskedText(e, mask, brand);
    onChange(e);
  }, []);

  const handleKeyUp = useCallback((e) => {
    generateMaskedText(e, mask, brand);
  }, [ mask, brand ]);
  const getStatus = () => {
    let _status = '';
    if (status) {
      _status = status;
    } else if (hasError) {
      _status = 'error';
    } else if (disabled) {
      _status = 'disabled';
    }
    return _status;
  };

  const getStatusIcon = () => {
    let _icon = '';
    const _status = getStatus();
    if (_status === 'success') {
      _icon = 'ic-check-circle-outline';
    } else if (_status === 'error') {
      _icon = 'alert-error-ic';
    }
    return _icon;
  };

  const getStatusColor = () => {
    let _color = '';
    const _status = getStatus();
    if (_status === 'success') {
      _color = successColor;
    } else if (_status === 'error') {
      _color = errorColor;
    }
    return _color;
  };

  const showFeedbackStatus = () => {
    return hasError || statusMessage;
  };

  const getStatusMessages = () => {
    let messages = [];
    if (status && statusMessage) {
      if (Array.isArray(statusMessage)) {
        messages = statusMessage;
      } else {
        messages = [ statusMessage ];
      }
    } else if (hasError && errorMessage) {
      if (Array.isArray(errorMessage)) {
        messages = errorMessage;
      } else {
        messages = [ errorMessage ];
      }
    }
    return messages;
  };

  return (
    <FormGroup style={wrapperStyle}>
      {label
        ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
            <Label htmlFor={name} style={labelStyle}>
              {label}
              {required ? <Required title={t('text.required')}>*</Required> : null}

              {labelDescription
                ? (
                  <LabelDescription>
                    <small>i</small>
                    <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(labelDescription) }}></p>
                  </LabelDescription>
                )
                : null}
            </Label>
            {name === 'cep' && isBrasilianUser && (
              <Cep rel="noreferrer" href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">
                {t('text.dontKnowMyCep')}
              </Cep>
            )}
          </div>
        )
        : null}
      <InputContainer>
        <Input
          autoFocus={autofocus}
          isInvestMe={isInvestMe}
          placeholderColor={themeContext.color.placeHolderColor}
          inputColor={themeContext.color.inputColor}
          type={_type || type}
          disabled={disabled}
          name={name}
          id={name}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          pattern={pattern}
          isLogin={isLogin}
          status={getStatus()}
          inputWithButton={actionButton ? true : false}
          onKeyUp={mask !== undefined ? handleKeyUp : () => {}}
          style={inputStyle}
          value={value}
          hasIcon={checked || clear || isPassword || brandIcon}
          autoComplete={autoComplete}
        />
        {loading ? <Loader /> : null}
        {checked
          ? (
            <ContainerIcon>
              <Svg name="ia-alert-check-ic" height="20px" width="20px" color={successColor} />
            </ContainerIcon>
          )
          : null}
        {clear
          ? (
            <ContainerIcon onClick={clearAction}>
              <Svg name="ic-close" height="20px" width="20px" />
            </ContainerIcon>
          )
          : null}
        {isPassword
          ? (
            <Password onClick={togglePassword}>
              <Svg name={!isPasswordVisible ? 'eye-outline' : 'eye-off-outline'} height="20px" width="20px" color={passIconColor} />
            </Password>
          )
          : null}
        {brandIcon ? <BrandCardImage src={brandIcon} alt={brand} /> : null}
      </InputContainer>
      {showFeedbackStatus()
        ? getStatusMessages().map((message, index) => {
          return (
            <FeedbackStatus key={index} status={getStatus()}>
              <SvgIcon name={getStatusIcon()} width="18px" height="18px" style={{ marginRight: '2px', alignSelf: 'flex-start' }} color={getStatusColor()} />
              <span>{message}</span>
            </FeedbackStatus>
          );
        })
        : null}
    </FormGroup>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default React.memo(TextInput);
