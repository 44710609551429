import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';
import JsConfig from '../../src/js-config';

export default class NLLoggerEncoder {
  static prepareData(data) {
    let filteredData;

    if (data !== null && Array.isArray(data)) {
      filteredData = [];
      data.forEach((el) => {
        filteredData.push(NLLoggerEncoder.prepareData(el));
      });
    } else if (data !== null && typeof data === 'object') {
      filteredData = {};
      Object.keys(data).forEach((key) => {
        if (!NLLoggerEncoder.isSensitiveKeyToRemove(key)) {
          if (NLLoggerEncoder.isSensitiveKeyToOmit(key)) {
            try {
              filteredData[key] = JsConfig.IS_NEW_LOG_API
                ? NLLoggerEncoder.omitData(data[key], key)
                : NLLoggerEncoder.encrypt(JSON.stringify(data[key]));
            } catch {
              // do nothing
            }
          } else {
            filteredData[key] = NLLoggerEncoder.prepareData(data[key]);
          }
        }
      });
    } else if (typeof data === 'string') {
      try {
        const parsedData = JSON.parse(data);
        filteredData = NLLoggerEncoder.prepareData(parsedData);
      } catch {
        filteredData = data;
      }
    } else {
      filteredData = data;
    }
    return filteredData;
  }

  static isSensitiveKeyToRemove(key) {
    return (
      key.match(/(senha|password|document|strCPF_CNPJ|strNumber|nSecurityCode|access_token|Authorization)/i) !== null
    );
  }

  static isSensitiveKeyToOmit(key) {
    return (
      key.match(
        /(username|firstName|lastName|strSurname|email|birth|phone|address|postCode|strZipCode|publicPlace|street|neighbor|city|state|district|paymentNetwork|nCartaoCreditoID|lastNumbers|holderName|fullExpirationDate|expirationDate|ip)/i,
      ) !== null
    );
  }

  static isAddressKey(key) {
    return (
      key.match(/(address|publicPlace|street|neighbor|city|state|district|postCode|strZipCode|addressComplement)/i) !==
      null
    );
  }

  static encrypt(data) {
    const defaultKey = JsConfig.LOG_ENCODE_KEY;
    const defaultIV = JsConfig.LOG_ENCODE_IV;

    const key = CryptoJS.enc.Utf8.parse(defaultKey);
    const iv = CryptoJS.enc.Utf8.parse(defaultIV);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  static assimetricEncrypt(data) {
    const crypt = new JSEncrypt();
    crypt.setPublicKey(JsConfig.LOG_ENCODE_KEY);
    const encrypted = crypt.encrypt(data);
    return encrypted;
  };

  static decrypt(encryptedData) {
    const crypt = new JSEncrypt();
    crypt.setPrivateKey(JsConfig.LOG_ENCODE_PRIVATE_KEY);
    return crypt.decrypt(encryptedData);
  };

  static omitData(data, key) {
    if (data === null) return null;
    if (data?.includes('@')) {
      let atIndex = data.indexOf('@');
      return data.slice(0, 3) + '*'.repeat(atIndex - 3) + data.slice(atIndex);
    } else if (NLLoggerEncoder.isAddressKey(key) && data !== null) {
      let words = data.split(' ');
      let obscuredWords = words.map((word) => {
        if (word.length <= 3) {
          return word;
        } else {
          return word.slice(0, 3) + '*'.repeat(word.length - 3);
        }
      });
      return obscuredWords.join(' ');
    } else {
      return data.slice(0, 3) + '*'.repeat(data.length - 3);
    }
  }
}
