import React, { useState, useRef, useContext, useEffect } from 'react';
import { Container, Input, ResultContainer, ResultItem, DarkSpan, ResultSpan, TabFilter, ContainerTab, ResultType, ResultImageContainer, ResultImage, TextContainer, ShortcutBadge } from './styles';
import Svg from '../../handlers/HandleSvg';
import { search } from '../../modules/search';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useOutsideHandler, useResponsive } from '../../helpers/HooksHelper';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import EnvHelper from '../../helpers/EnvHelper';
import NLLogger from '@common/classes/nelogica/NLLogger';
import tabFilterItems from '../../data/tabfilterItems';
import { setIsPlaylist } from '../../store/actions/app';
import useKeyboardShortcut from '@/hooks/KeyboardShortcut';

const COURSEID_2MV = 160;
const COURSEID_AUTOTRADING = 161;
const COURSEID_INTERNACIONAL = 162;

const InputSearch = ({ hideIcon = false, setInputOpen = () => {} }) => {
  const [ loading, setLoading ] = useState(false);
  const [ term, setTerm ] = useState('');
  const [ resultOpen, setResultOpen ] = useState(false);
  const [ results, setResults ] = useState([]);
  const [ allResults, setAllResults ] = useState([]);
  const [ hasError, setHasError ] = useState(false);
  const [ notFound, setNotFound ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ searchDebounce, setSearchDebounce ] = useState();
  const { isLoggedIn } = useSelector(state => state.auth);
  const { user } = useSelector(state => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n: { language } } = useTranslation('translations');
  const inputRef = useRef();
  const themeContext = useContext(ThemeContext);
  const { isMobileSmall, isMobile } = useResponsive();
  const { playlist } = useSelector(state => state.app);
  const [ tabItems, setTabItems ] = useState(tabFilterItems);
  const [ selectedIndex, setSelectedIndex ] = useState(null);
  const b3DefaultImage = require('../../img/b3-default.png');

  const logData = {
    time: new Date().toLocaleString(),
    user: isLoggedIn ? user.id : undefined,
    browser: EnvHelper.browserDetect(),
  };

  useOutsideHandler(inputRef, () => {
    if (!EnvHelper.isInvestMe()) setResultOpen(false);
  });

  useEffect(() => {
    if (EnvHelper.isInvestMe()) {
      setResultOpen(true);
    }
  }, [ EnvHelper.isInvestMe() && isMobile ]);

  const canShowResult = () => {
    return (term || '').length >= 1;
  };

  const canSearch = () => {
    return (term || '').length >= 3;
  };

  const handleSearch = () => {
    setLoading(true);
    setLoaded(false);
    setTabItems(tabFilterItems);

    clearTimeout(searchDebounce);

    if (!canSearch()) {
      setResults([]);
      setSelectedIndex(0);
      setNotFound(false);
      setLoading(false);
      setLoaded(true);
      setResultOpen(canShowResult());

      return;
    }

    setSearchDebounce(setTimeout(async () => {
      setResults([]);
      setSelectedIndex(0);
      setHasError(false);

      const resp = await search.courses({ strName: term });

      if (!resp.success) {
        NLLogger.doLog('ERROR', 'Erro genérico ao listar cursos pela busca', { resp, ...logData });
        setHasError(true);
      } else {
        if (EnvHelper.isInvestMe()) {
          mergeResults(resp.data.data, term);
        } else {
          setResults(resp.data.data);
          if (resp.data.length === 0) {
            setNotFound(true);
          }
        }
      }

      setResultOpen(true);
      setLoading(false);
      setLoaded(true);
    }, 600));
  };

  const mergeResults = (arrCourses = [], value) => {
    const playlistResult = playlist.filter((item) => {
      return item.strNamePlaylist.toLowerCase().includes(value.toLowerCase().trim());
    });

    const courseResult = arrCourses.length > 0
      ? arrCourses.map((el) => {
        const strBackgroundColor = el.strBackgroundColor ? el.strBackgroundColor.split(',') : [ '#024585', '#2B7EB1' ];
        return {
          id: el.active_class ? el.active_class.nClassID : 0,
          strName: el.strName,
          type: 'course',
          strURLImage: el.strLogo ? el.strLogo : b3DefaultImage,
          haveImage: !!el.strLogo,
          strBackgroundColor,
          strInstructor: el.instructors?.length !== 0 ? el?.instructors[0].strName : null,
        };
      })
      : [];

    const mergedResults = courseResult.concat(playlistResult.map((el) => {
      return {
        id: el.nPlaylistID,
        strName: el.strNamePlaylist,
        type: el.type,
        strURLImage: el.strURLImage ? el.strURLImage : b3DefaultImage,
        haveImage: !!el.strURLImage,
        strBackgroundColor: null,
      };
    }));

    if (mergedResults.length === 0) {
      setNotFound(true);
    }

    setResults(mergedResults);
    setAllResults(mergedResults);
  };

  const clearSearch = () => {
    inputRef.current.value = '';
    setTerm('');
    setResults([]);
    setAllResults([]);
    setNotFound(false);
    setLoading(false);
    setLoaded(true);
    setInputOpen(false);
  };

  const getCourseLPUrl = (nID, nCourseID) => {
    let url = `/${language}/courselp/${nID}`;
    if (COURSEID_2MV === nCourseID) url = `/${language}/mentoria2mv`;
    else if (COURSEID_AUTOTRADING === nCourseID) url = `/${language}/formacaoAutoTrading`;
    else if (COURSEID_INTERNACIONAL === nCourseID) url = `/${language}/mentoria-internacional`;
    return url;
  };

  const redirect = (id, type, nCourseID) => {
    const iframe = document.getElementById('playlistIframe');
    const reactParent = document.getElementById('reactParent');
    if (type === 'course' || type === undefined) {
      const url = getCourseLPUrl(id, nCourseID);

      history.push(url);

      if (history.location.pathname.includes('courselp')) document.location.reload(true);
      if (iframe) iframe.style.display = 'none';
      if (reactParent) reactParent.style.display = 'block';
      dispatch(setIsPlaylist(false));
    } else {
      iframe.style.display = 'block';
      reactParent.style.display = 'none';
      // push message before loading
      iframe.addEventListener('load', () => {
        iframe.contentWindow.postMessage(`${id}`, '*');
      });
      iframe.contentWindow.postMessage(`${id}`, '*');
      dispatch(setIsPlaylist(true));
    }
    setResultOpen(false);
    setTerm('');
  };

  const getSearchIconColorByProduct = () => {
    if (EnvHelper.isInvestMe()) {
      return themeContext.color.neutral.high.pure;
    }

    return themeContext.color.neutral.high.dark;
  };

  const filterResults = (type) => {
    const filtered = allResults.filter(el => el.type === type);

    if (filtered.length > 0) {
      setResults(filtered);
    } else {
      setResults(allResults);
    }

    const updateTabItems = tabItems.map((el) => {
      return { ...el, active: (el.id === type) };
    });

    setTabItems(updateTabItems);
  };

  const arrTabFilter = tabItems.map((item) => {
    if (EnvHelper.isInvestMe()) {
      return (
        <TabFilter key={item.id}>
          <a
            id={item.id}
            className={item.active ? 'active' : ''}
            onClick={() => filterResults(item.id)}
          >
            <span>{item.text}</span>
          </a>
        </TabFilter>
      );
    }
    return;
  });

  useEffect(() => {
    setResultOpen(canShowResult());
    handleSearch();
  }, [ term ]);

  useKeyboardShortcut(() => {
    inputRef.current.focus();
  }, { code: 'Slash', ctrlKey: true });

  useKeyboardShortcut(() => {
    clearSearch();
    if (inputRef.current) inputRef.current.blur();
  }, { code: 'Escape' });

  useKeyboardShortcut(() => {
    const activeElement = document.querySelector('.search-dropdown-container .active');
    activeElement && activeElement.click();
  }, { code: 'Enter', shortcutTarget: inputRef.current });

  useKeyboardShortcut((e) => {
    e.preventDefault();
    if ((results.length - 1) > selectedIndex) setSelectedIndex(selectedIndex + 1);
  }, { code: 'ArrowDown', shortcutTarget: inputRef.current });

  useKeyboardShortcut((e) => {
    e.preventDefault();
    if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
  }, { code: 'ArrowUp', shortcutTarget: inputRef.current });

  useEffect(() => {
    const activeElement = document.querySelector('.search-dropdown-container .active');
    activeElement && activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [ selectedIndex ]);

  return (
    <>
      <Container isInvestMe={EnvHelper.isInvestMe()}>
        { !hideIcon && <Svg name="ic-search" width="20px" height="20px" color={getSearchIconColorByProduct()} /> }
        <Input
          autoFocus
          onChange={(event) => {
            setTerm(event.target.value);
          }}
          isInvestMe={EnvHelper.isInvestMe()}
          type="text"
          placeholder={t('headerMenu.searchCourses')}
          value={term}
          ref={inputRef}
          onFocus={() => {
            setResultOpen(canShowResult());
          }}
        />
        { !hideIcon && !term && !isMobile
        && <ShortcutBadge>Crtl /</ShortcutBadge>}
        { loading && <Svg name="loading" width="16px" height="16px" color={themeContext.color.neutral.high.dark} /> }
        { ((hideIcon && !isMobileSmall) || (term && !loading))
        && <Svg name="ic-close" width="20px" height="20px" color={getSearchIconColorByProduct()} onClick={clearSearch} />}
        { resultOpen && (
          <ResultContainer isInvestMe={EnvHelper.isInvestMe()} className={`search-dropdown-container ${isMobileSmall ? 'mobile' : ''}`}>
            { notFound || hasError
              ? (
                <ResultItem>
                  <DarkSpan>{ t(hasError ? 'results.error' : 'results.notFound') }</DarkSpan>
                </ResultItem>
              )
              : (
                <React.Fragment>
                  { results.length > 0 && (
                    <ContainerTab className="invest-me-nav">
                      <nav>
                        <ul id="tabsFiltes">{arrTabFilter}</ul>
                      </nav>
                    </ContainerTab>
                  )}

                  <ResultItem footer_text={true}>
                    { loaded && term.length > 2 && (
                      <DarkSpan resultOpen={resultOpen}>
                        {results.length} {t('results.for')} &quot;
                        {term}
                        &quot;
                      </DarkSpan>
                    )}
                    { term.length > 0 && term.length <= 2 && (
                      <DarkSpan resultOpen={resultOpen}>
                        { t('results.minimum') }
                      </DarkSpan>
                    )}
                  </ResultItem>

                  { EnvHelper.isInvestMe()
                    ? (
                      <>
                        { results.map(({ strName, type, id, strBackgroundColor, strURLImage, haveImage, strInstructor, nCourseID }, idx) => (
                          <ResultItem key={idx} onClick={() => redirect(id, type, nCourseID)} resultOpen={resultOpen} link={true} className={idx === selectedIndex ? 'active' : null}>
                            <ResultImageContainer haveImage={haveImage} backgroundColor={strBackgroundColor}>
                              <ResultImage src={strURLImage}></ResultImage>
                            </ResultImageContainer>
                            <TextContainer>
                              <ResultType>{type === 'course' ? 'Curso' : 'Playlist'}</ResultType>
                              <ResultSpan>{strName.trim()}</ResultSpan>
                              {strInstructor && (
                                <DarkSpan>
                                  Prof.
                                  {strInstructor}
                                </DarkSpan>
                              )}
                            </TextContainer>
                          </ResultItem>
                        ))}
                      </>
                    )
                    : (
                      <>
                        { results.map(({ strName, active_class, strBackgroundColor, strLogo, strBanner, type, instructors, nCourseID }, idx) => (
                          <ResultItem key={idx} onClick={() => redirect(active_class.nClassID, type, nCourseID)} resultOpen={resultOpen} link={true} className={idx === selectedIndex ? 'active' : null}>
                            <ResultImageContainer haveImage={strLogo} backgroundColor={strBackgroundColor}>
                              <ResultImage src={strLogo} zIndex={2}></ResultImage>
                              <ResultImage src={strBanner} zIndex={1}></ResultImage>
                            </ResultImageContainer>
                            <TextContainer>
                              <ResultSpan>{strName.trim()}</ResultSpan>
                              {instructors.length !== 0 && (
                                <DarkSpan>
                                  Prof.
                                  {instructors[0].strName}
                                </DarkSpan>
                              )}
                            </TextContainer>
                          </ResultItem>
                        ))}
                      </>
                    ) }
                </React.Fragment>
              )}
          </ResultContainer>
        )}
      </Container>
    </>
  );
};

export default InputSearch;
