import Request from '../helpers/RequestHelper';
import RequestHandler from '../handlers/RequestHandler';

const _api = 'app-b3/';
const _playlists = 'playlists/';

export const playlists = {
  async searchPlaylist() {
    try {
      const resp = await Request.get(`${_api}${_playlists}search`, false);
      return RequestHandler.processResponse(resp);
    } catch (error) {
      return RequestHandler.processResponse(error);
    }
  },
};
