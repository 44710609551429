import Request from '../helpers/RequestHelper';
import { db } from './db';
import axios from 'axios';

const mustLogRequest = (process.env.REACT_APP_MUST_LOG || '').toLowerCase() === 'true';
export const envId = process.env.REACT_APP_WL_ENVIRONMENT_ID ?? null;
const statisticsLogsTableName = 'logs';
let isStatisticsLogging = false;

export default class Logger {
  static doLogRequest(url, data) {
    Logger.doLog('INFO', 'REQUEST', {
      url,
      data,
    });
  }

  static doLogResponse(hasError, url, data) {
    Logger.doLog(hasError ? 'ERROR' : 'INFO', 'RESPONSE', {
      url,
      data,
    });
  }

  static doLog(status, message, data) {
    if (!mustLogRequest) return;

    // TO FIX: Por ora, vamos deixar sem as chamadas de API porque nossos logs estavam floodando os serviores
    // Rever todos os logs.

    // const apiUrl = process.env.REACT_APP_LOG_API;
    // const body = JSON.stringify({
    //   location: window.location.protocol + '//' + window.location.host + window.location.pathname,
    //   status,
    //   message,
    //   data,
    // });
    // Request.post(apiUrl, body);

    return { status, message, data };
  }

  static async doIndexedDBLog(data) {
    if (!mustLogRequest) return;

    try {
      await db.table(statisticsLogsTableName).add(data);
    } catch (error) {
      console.error('Error adding record:', error);
    }
  }

  static serialize(arrObj) {
    return arrObj.reduce((acc, obj) => acc.concat(`{"index":{}}\r\n${JSON.stringify(obj)}\r\n`), '');
  }

  static async doLogStatistics() {
    if (!mustLogRequest || isStatisticsLogging) return;

    try {
      isStatisticsLogging = true;
      const apiUrl = process.env.REACT_APP_LOG_STATISTICS_API;
      const allData = await db.table(statisticsLogsTableName).toCollection().toArray();

      const headers = {
        'Content-Type': 'text/plain',
      };

      const preparedJson = allData.map((log) => {
        return {
          '@timestamp': log.timestamp,
          'hardwareID': log.hardwareID,
          'userID': log.nUserID,
          'browser': log.browser,
          'type': log.type,
          'developer': log.developer,
          'product': log.product,
        };
      });
      const body = this.serialize(preparedJson);

      axios.post(apiUrl, body, { headers })
        .then((response) => {
          if (response.status === 200) {
            const sendedIds = [ ...allData.map(log => log.id) ];

            db.table(statisticsLogsTableName)
              .where('id').anyOf(sendedIds)
              .delete();
          }
        })
        .catch ((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    } finally {
      isStatisticsLogging = false;
    }
  }

  static isStatisticsLogTableEmpty() {
    return db.table(statisticsLogsTableName).count() === 0;
  }

  static doPurchaseLog(status, message, data) {
    if (!mustLogRequest) return;

    const apiUrl = process.env.REACT_APP_PURCHASE_LOG_API;

    const body = JSON.stringify({
      nUsuarioID: data.userId,
      nPaisID: data.countryId,
      nIdiomaID: data.langId,
      nProdutoSoftwareID: data.prodId,
      strPurchaseStatus: status,
      strMessage: message,
      nPlatformTypeID: data.platformId,
      nPlanoAssinaturaID: data.planId,
    });

    Request.post(apiUrl, body);
  }

  static mustLogRequest() {
    return mustLogRequest;
  }
}
