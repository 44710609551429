import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from './styles';
import i18n from '../../plugins/i18n';
import { compile, pathToRegexp } from 'path-to-regexp';
import { useTranslation } from 'react-i18next';
import Svg from '../../handlers/HandleSvg';
import { ThemeContext } from 'styled-components';

const generateLanguage = (locale, location) => {
  const ROUTE = '/:locale/:path*';
  const definePath = compile(ROUTE);
  const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);

  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split('/');
  }

  return definePath({
    locale,
    path: subPaths,
  });
};

const LocaleSwitcher = React.forwardRef(({ open, setOpen = () => {} }, ref) => {
  const history = useHistory();
  const location = useLocation();
  // const [openMenu, setOpenMenu] = useState(false);
  const { t } = useTranslation('translations');
  const themeContext = useContext(ThemeContext);

  const langs = [
    {
      code: 'pt',
      name: t('langs.pt'),
      image: '/assets/images/brazil.png',
    },
    {
      code: 'en',
      name: t('langs.en'),
      image: '/assets/images/united-states.png',
    },
    {
      code: 'es',
      name: t('langs.es'),
      image: '/assets/images/spanish.png',
    },
  ];
  return (
    <Container
      ref={ref}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className="selected-language">
        <img src={langs.find(lang => lang.code === i18n.language).image} alt={i18n.language} />
        <span>
          { langs.find(lang => lang.code === i18n.language).name }
        </span>
        <Svg name="ic-arrow-down" width="16px" height="16px" color={themeContext.color.tittle} iconClass={open ? 'active-collapsible' : null} />
      </div>
      {open && (
        <ul className="dropdown-container">
          { langs.map((lang, index) => (
            <li
              key={index}
              onClick={
                () => {
                  i18n.changeLanguage(lang.code);
                  history.push(generateLanguage(lang.code, location));
                  setOpen(false);
                }
              }
            >
              <img src={lang.image} alt={lang.name} />
              <span>{lang.name}</span>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
});

LocaleSwitcher.displayName = 'LocaleSwitcher';
export default LocaleSwitcher;
