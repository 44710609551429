import { types } from '../actions/types';

const initialState = {
  courseLpName: '',
};

const courseLpReducer = (state = initialState, action) => {
  const { COURSELP } = types;
  const { type, payload } = action;

  switch (type) {
    case COURSELP.SET_COURSE_NAME:
      return {
        ...state,
        courseLpName: payload,
      };
    default:
      return state;
  }
};

export default courseLpReducer;
