import axios from 'axios';
import EnvHelper from '../helpers/EnvHelper';
import UserHelper from '../helpers/UserHelper';

export const getDefaultHeaders = (needsAuth = false) => {
  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
  };
  if (EnvHelper.envId()) {
    defaultHeaders.Environment = 'EducationalWL';
    defaultHeaders.EnvironmentId = EnvHelper.envId();
  }
  if (UserHelper.isLogged || needsAuth) {
    defaultHeaders.Authorization = `Bearer ${UserHelper.getBearerToken()}`;
  }

  return defaultHeaders;
};

const TIMEOUT = 30000;

const client = axios.create({
  baseURL: process.env.REACT_APP_WEB_API,
  headers: getDefaultHeaders(),
});

client.defaults.timeout = TIMEOUT;

export default client;
