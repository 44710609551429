import client, { getDefaultHeaders } from '../service/api';
import UrlHelper from './UrlHelper';

const RequestHelper = {
  post(url, data, headers = null) {
    headers = headers || getDefaultHeaders();
    return client
      .post(url, data, {
        headers: headers,
      });
  },

  put(url, data, headers = null) {
    headers = headers || getDefaultHeaders();
    return client
      .put(url, data, {
        headers: headers,
      });
  },

  delete(url, data, headers = null) {
    headers = headers || getDefaultHeaders();
    return client
      .delete(url, {
        headers,
        data,
      });
  },

  get(
    endpoint,
    needsAuth = true,
    queryParameters = null,
    headers = null,
    params = null,
  ) {
    headers = headers || getDefaultHeaders(needsAuth);
    let finalEndpoint = endpoint[0] === '/' ? endpoint : `/${endpoint}`;
    if (queryParameters)
      finalEndpoint += UrlHelper.mountQueryString(queryParameters);
    return client.get(finalEndpoint, { headers, params });
  },

  patch(endpoint, data, headers = null) {
    headers = headers || getDefaultHeaders();
    return client.patch(endpoint, data, { headers: headers, method: 'patch' });
  },
};

export default RequestHelper;
