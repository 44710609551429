import { getNameByProduct } from '../../../styles/theme/themes';

const course = [
  {
    classID: 46,
    teachersName: [ 'Wilson Neto' ],
    courseID: 75,
    courseColor: '#2b9ed8',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#024585', '#2B7EB1' ],
    nTotalLessons: 15,
    nLives: 5,
    level: 'Iniciante',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728221590?h=7ac0c497f2',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-26_10-07-02_Logo_NTG_footer.svg.svg',
    campaignBenefit: '',
    bannerSection: {
      title: 'Faça o dinheiro trabalhar para você e conquiste sua ',
      titleStrong: 'independência financeira',
      informations: [
        {
          icon: 'ia-cap-ic',
          text: '<strong>15</strong> videoaulas dinâmicas, distribuídas em <strong>5</strong> módulos ao longo de <strong>5</strong> semanas. Os módulos serão liberados às segundas-feiras, <strong>a partir de 28/11</strong>',
        },
        {
          icon: 'ia-microphone-ic',
          text: '<strong>6</strong> podcasts com convidados do mercado financeiro',
        },
        {
          icon: 'ia-certificate-ic',
          text: '<strong>15</strong> resumos das aulas no formato de textos',
        },
        {
          icon: 'ic-check',
          text: 'Questões práticas para testar seu aprendizado',
        },
      ],
    },
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Entenda as vantagens e os riscos de operar minicontratos de índice e dólar',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_1.svg',
        },
        {
          title: 'Domine os conceitos de margem de garantia e alavancagem',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_2.svg',
        },
        {
          title: 'A prática do mercado, por meio de aulas dinâmicas e cenários reais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_3.svg',
        },
        {
          title: 'Proteja seu capital com técnicas de gerenciamento de risco profissional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_4.svg',
        },
        {
          title: 'Estude as melhores técnicas e se destaque entre os grandes players',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_5.svg',
        },
        {
          title: 'Aprenda a ganhar com os movimentos do mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_6.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um conjunto de técnicas validada por grandes profissionais',
          'Uma combinação vencedora de teoria e prática',
          'A melhor forma de aprender a operar com consistência',
          'A grande oportunidade para elevar seus trades a outro patamar',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma fórmula de ganhos rápidos e garantidos',
          'Um passo a passo para ganhar dinheiro fácil',
          'Um curso sem aplicação prática',
          'Para quem não está disposto a correr riscos',
        ],
      },
    },
    infoSection: {
      title: 'O método NTG',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Teoria_Mais_Pratica.jpg',
          title: 'Teoria + prática',
          description: 'Aprenda os principais conceitos do mercado e coloque em prática entendendo como, por que e o que está sendo feito.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Experiencia.jpg',
          title: 'Experiência',
          description: 'Aprenda com Wilson Neto, trader referência no mercado com mais de duas décadas de experiência.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Vida_Real.jpg',
          title: 'Vida real',
          description: 'Sem fórmulas fáceis e sem truques: você vai aprender os riscos e benefícios de operar em um cenário de alta performance.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que a Trilha do Gain vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Toda recompensa exige um risco. Aprenda a dominar essa e outras regras do jogo que vão mudar o seu jeito de operar. Não perca mais tempo.',
      information: [
        'Módulos liberados toda segunda-feira, a partir de 28/11',
        '5 lives para tirar dúvidas toda terça-feira, às 20h',
        '60 dias de acesso ao Plugin PNB',
        '6 podcasts exclusivos',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
      ],
    },
  },
  {
    classID: 84,
    teachersName: [ 'Fabrício Lodi', 'Valério Klug' ],
    courseID: 78,
    courseColor: '#9E70B3',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#4C265B', '#704293' ],
    nTotalLessons: 28,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728982698?h=14f8abc5c7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-26_10-07-02_Logo_MercadodeOp%C3%A7%C3%B5es_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Entenda a lógica do Mercado de Opções, sua origem e como faturar mais com ele',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Aprender/icones_1.svg',
        },
        {
          title: 'Aprenda a estruturar opções, criando estratégias vencedoras de longo prazo',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Aprender//icones_2.svg',
        },
        {
          title: 'Domine conceitos como call e put, opções de balcão, ITM, ATM e OTM',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Aprender//icones_3.svg',
        },
        {
          title: 'Conheça o modelo Black & Scholes de precificação, linhas Smille e muito mais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Aprender//icones_4.svg',
        },
        {
          title: 'Saiba como otimizar suas operações de maneira profissional com o Módulo de Opções exclusivo do Profit',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Aprender//icones_5.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Ensino de técnicas de alto desempenho validadas por profissionais',
          'O ensino de um modelo de continuidade das operações',
          'Uma combinação entre teoria e prática',
          'Aprendizado sobre risco e retorno e gerenciamento de resultados',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma garantia de resultado sem esforço e dedicação',
          'A entrega de fórmulas rápidas ou técnicas de ganho sem fundamento',
          'Baseado em promessas e ilusões, e sim em fatos',
          'Para quem deseja enriquecer "da noite para o dia"',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Conhecimento.jpg',
          title: 'Consistência',
          description: 'Sem espaço para especulações. Por meio do sistema gerencial e de operações estruturadas, você aprenderá a gerir resultados de longo prazo',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Consistencia.jpg',
          title: 'Conhecimento',
          description: 'Unindo teoria com a expertise prática dos professores, vamos desmistificar tudo o que você já aprendeu sobre o Mercado de Opções até agora',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Opcoes/Risco_Retorno.jpg',
          title: 'Risco e retorno',
          description: 'Compreendendo esta relação, os alunos terão propriedade para montar suas operações e projetar seus resultados com base na própria realidade',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Mercado de Opções vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Não seja mais um no mercado financeiro. Essa é a oportunidade de aprender as técnicas e estratégias para lucrar no Mercado de Opções. Evite as falsas promessas e venha estudar com quem realmente entende do assunto.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses',
        'Certificado de conclusão',
        'Conteúdos em formato de vídeos, textos e podcasts ',
        'Disponível também na versão mobile',
        'Podcasts exclusivos',
      ],
    },
  },
  {
    classID: 74,
    teachersName: [ 'Marcos Moore' ],
    courseID: 77,
    courseColor: '#607935',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#415323', '#4D862C' ],
    nTotalLessons: 16,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/730420473?h=b2c9220173&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-26_10-07-14_Logo_Agrotrading_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Como funcionam os contratos de agro na Bolsa de Valores',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__01.svg',
        },
        {
          title: 'Os conceitos de mercado futuro e as regras de garantia e alavancagem',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__02.svg',
        },
        {
          title: 'A identificar oportunidades em ativos mais estáveis e direcionais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__03.svg',
        },
        {
          title: 'Setups matadores para operar os contratos futuros de milho, boi e café',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__04.svg',
        },
        {
          title: 'Gerenciar o risco e as emoções durante as operações',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__05.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Conteúdo prático: entenda a teoria e aprenda fazendo',
          'Um conjunto de técnicas para dominar os contratos de commodities agrícolas',
          'Uma forma de buscar alto índice de acerto em operações swing trade',
          'Focado no gerenciamento das suas emoções e do seu capital',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Acesso rápido e fácil a lucros extraordinários',
          'Uma desculpa para abandonar sua fonte de renda e sair operando',
          'Um setup pronto com indicadores que operam por você',
          'Focado em operações day trade ou scalping',
        ],
      },
    },
    infoSection: {
      title: 'O método Agro Trading',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Conhecer.jpg',
          title: 'Conhecer',
          description: 'Entenda tudo sobre esse mercado: regras dos contratos de commodities, operações de futuros, garantia, alavancagem e muito mais',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Aplicar.jpg',
          title: 'Aplicar',
          description: 'Não fique só observando! Aplique a teoria em situações reais com o Simulador do Profit e veja os resultados. ',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Gerenciar.jpg',
          title: 'Gerenciar',
          description: 'Aprenda a administrar o capital gerenciando o risco e dominando a parte emocional do trade para alcançar ganhos mais consistentes.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Agro Trading vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Não seja mais uma sardinha: encontre oportunidades onde poucos estão operando e aproveite o momento do mercado de commodities para extrair os melhores resultados e alavancar seus ganhos.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        '4 podcasts exclusivos',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Certificado de conclusão',
      ],
    },
  },
  {
    classID: 74,
    teachersName: [ 'Marcos Moore' ],
    courseID: 77,
    courseColor: '#607935',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#415323', '#4D862C' ],
    nTotalLessons: 16,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/730420473?h=b2c9220173&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-26_10-07-14_Logo_Agrotrading_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Como funcionam os contratos de agro na Bolsa de Valores',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__01.svg',
        },
        {
          title: 'Os conceitos de mercado futuro e as regras de garantia e alavancagem',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__02.svg',
        },
        {
          title: 'A identificar oportunidades em ativos mais estáveis e direcionais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__03.svg',
        },
        {
          title: 'Setups matadores para operar os contratos futuros de milho, boi e café',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__04.svg',
        },
        {
          title: 'Gerenciar o risco e as emoções durante as operações',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Icones__05.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Conteúdo prático: entenda a teoria e aprenda fazendo',
          'Um conjunto de técnicas para dominar os contratos de commodities agrícolas',
          'Uma forma de buscar alto índice de acerto em operações swing trade',
          'Focado no gerenciamento das suas emoções e do seu capital',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Acesso rápido e fácil a lucros extraordinários',
          'Uma desculpa para abandonar sua fonte de renda e sair operando',
          'Um setup pronto com indicadores que operam por você',
          'Focado em operações day trade ou scalping',
        ],
      },
    },
    infoSection: {
      title: 'O método Agro Trading',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Conhecer.jpg',
          title: 'Conhecer',
          description: 'Entenda tudo sobre esse mercado: regras dos contratos de commodities, operações de futuros, garantia, alavancagem e muito mais',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Aplicar.jpg',
          title: 'Aplicar',
          description: 'Não fique só observando! Aplique a teoria em situações reais com o Simulador do Profit e veja os resultados. ',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Agro/Gerenciar.jpg',
          title: 'Gerenciar',
          description: 'Aprenda a administrar o capital gerenciando o risco e dominando a parte emocional do trade para alcançar ganhos mais consistentes.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Agro Trading vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Não seja mais uma sardinha: encontre oportunidades onde poucos estão operando e aproveite o momento do mercado de commodities para extrair os melhores resultados e alavancar seus ganhos.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        '4 podcasts exclusivos',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Certificado de conclusão',
      ],
    },
  },
  {
    classID: 54,
    teachersName: [ 'Jefferson Laatus' ],
    courseID: 80,
    courseColor: '#ffb600 ',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#866309', '#CC9203' ],
    nTotalLessons: 35,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728982620?h=f1b9a851d1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-29_09-07-58_GRatualizado_GerenciamentodeRisco_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Gerenciamento de risco com técnicas e ferramentas profissionais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_1.svg',
        },
        {
          title: 'A definir limites e metas de ganhos e construir um plano para seguir',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_2.svg',
        },
        {
          title: 'A administrar as emoções e encontrar seu estilo de trade',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_3.svg',
        },
        {
          title: 'A ganhar consistência e construir um plano de evolução no mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_4.svg',
        },
        {
          title: 'A adquirir experiência com um dos mais renomados traders do Brasil',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_5.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma experiência necessária e extremamente relevante para a sua maturidade e evolução como trader',
          'Vida real: situações verídicas de gerenciamento sob a ótica de quem entende do assunto',
          'Ensinamentos do mercado para você se manter “vivo” por mais tempo',
          'Soluções 360 para tudo que impede o trader de ter ganhos mais consistentes',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um apanhado de bons resultados e de dados otimistas sobre o mercado',
          'A ilusão de que viver do mercado financeiro é algo fácil ou simples',
          'Um caminho para quem não está disposto a dedicar tempo e esforço para evoluir',
          'Semelhante a qualquer outro curso de mesma temática existente no mercado',
        ],
      },
    },
    infoSection: {
      title: 'O método de Gerenciamento de Risco',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Profundidade.jpg',
          title: 'Profundidade',
          description: 'Em 35 aulas recheadas de conteúdo, Laatus compartilha muito da sua experiência e a metodologia  que utiliza para operar. É garantia de um aprendizado sólido.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Realidade.jpg',
          title: 'Realidade',
          description: 'Teoria aplicada na prática e troca de conhecimento com casos reais, que só um professor com mais de 22 anos de experiência na área pode oferecer',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Adaptacao.jpg',
          title: 'Adaptação',
          description: 'O curso vai te ensinar a caminhar com as próprias pernas, entregando conhecimento, antecipando dificuldades e tirando dúvidas para ajudar você a chegar mais rápido aos seus objetivos.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Gerenciamento de Risco vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'No mundo do trading, os ganhos podem ser ilimitados, mas as perdas também. Aprenda com quem vive o dia a dia do mercado, conheça os segredos para construir uma rotina consistente e transforme a maneira como você opera.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Foco na teoria e no preparo emocional',
      ],
    },
  },
  {
    classID: 83,
    teachersName: [ 'Jefferson Laatus' ],
    courseID: 80,
    courseColor: '#ffb600 ',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#866309', '#CC9203' ],
    nTotalLessons: 35,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728982620?h=f1b9a851d1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-29_09-07-58_GRatualizado_GerenciamentodeRisco_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Gerenciamento de risco com técnicas e ferramentas profissionais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_1.svg',
        },
        {
          title: 'A definir limites e metas de ganhos e construir um plano para seguir',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_2.svg',
        },
        {
          title: 'A administrar as emoções e encontrar seu estilo de trade',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_3.svg',
        },
        {
          title: 'A ganhar consistência e construir um plano de evolução no mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_4.svg',
        },
        {
          title: 'A adquirir experiência com um dos mais renomados traders do Brasil',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_5.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma experiência necessária e extremamente relevante para a sua maturidade e evolução como trader',
          'Vida real: situações verídicas de gerenciamento sob a ótica de quem entende do assunto',
          'Ensinamentos do mercado para você se manter “vivo” por mais tempo',
          'Soluções 360 para tudo que impede o trader de ter ganhos mais consistentes',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um apanhado de bons resultados e de dados otimistas sobre o mercado',
          'A ilusão de que viver do mercado financeiro é algo fácil ou simples',
          'Um caminho para quem não está disposto a dedicar tempo e esforço para evoluir',
          'Semelhante a qualquer outro curso de mesma temática existente no mercado',
        ],
      },
    },
    infoSection: {
      title: 'O método de Gerenciamento de Risco',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Profundidade.jpg',
          title: 'Profundidade',
          description: 'Em 35 aulas recheadas de conteúdo, Laatus compartilha muito da sua experiência e a metodologia  que utiliza para operar. É garantia de um aprendizado sólido.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Realidade.jpg',
          title: 'Realidade',
          description: 'Teoria aplicada na prática e troca de conhecimento com casos reais, que só um professor com mais de 22 anos de experiência na área pode oferecer',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Adaptacao.jpg',
          title: 'Adaptação',
          description: 'O curso vai te ensinar a caminhar com as próprias pernas, entregando conhecimento, antecipando dificuldades e tirando dúvidas para ajudar você a chegar mais rápido aos seus objetivos.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Gerenciamento de Risco vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'No mundo do trading, os ganhos podem ser ilimitados, mas as perdas também. Aprenda com quem vive o dia a dia do mercado, conheça os segredos para construir uma rotina consistente e transforme a maneira como você opera.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Foco na teoria e no preparo emocional',
      ],
    },
  },
  {
    classID: 54,
    teachersName: [ 'Jefferson Laatus' ],
    courseID: 80,
    courseColor: '#ffb600 ',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#866309', '#CC9203' ],
    nTotalLessons: 35,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728982620?h=f1b9a851d1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-29_09-07-58_GRatualizado_GerenciamentodeRisco_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Gerenciamento de risco com técnicas e ferramentas profissionais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_1.svg',
        },
        {
          title: 'A definir limites e metas de ganhos e construir um plano para seguir',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_2.svg',
        },
        {
          title: 'A administrar as emoções e encontrar seu estilo de trade',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_3.svg',
        },
        {
          title: 'A ganhar consistência e construir um plano de evolução no mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_4.svg',
        },
        {
          title: 'A adquirir experiência com um dos mais renomados traders do Brasil',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_5.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma experiência necessária e extremamente relevante para a sua maturidade e evolução como trader',
          'Vida real: situações verídicas de gerenciamento sob a ótica de quem entende do assunto',
          'Ensinamentos do mercado para você se manter “vivo” por mais tempo',
          'Soluções 360 para tudo que impede o trader de ter ganhos mais consistentes',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um apanhado de bons resultados e de dados otimistas sobre o mercado',
          'A ilusão de que viver do mercado financeiro é algo fácil ou simples',
          'Um caminho para quem não está disposto a dedicar tempo e esforço para evoluir',
          'Semelhante a qualquer outro curso de mesma temática existente no mercado',
        ],
      },
    },
    infoSection: {
      title: 'O método de Gerenciamento de Risco',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Profundidade.jpg',
          title: 'Profundidade',
          description: 'Em 35 aulas recheadas de conteúdo, Laatus compartilha muito da sua experiência e a metodologia  que utiliza para operar. É garantia de um aprendizado sólido.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Realidade.jpg',
          title: 'Realidade',
          description: 'Teoria aplicada na prática e troca de conhecimento com casos reais, que só um professor com mais de 22 anos de experiência na área pode oferecer',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Adaptacao.jpg',
          title: 'Adaptação',
          description: 'O curso vai te ensinar a caminhar com as próprias pernas, entregando conhecimento, antecipando dificuldades e tirando dúvidas para ajudar você a chegar mais rápido aos seus objetivos.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Gerenciamento de Risco vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'No mundo do trading, os ganhos podem ser ilimitados, mas as perdas também. Aprenda com quem vive o dia a dia do mercado, conheça os segredos para construir uma rotina consistente e transforme a maneira como você opera.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Foco na teoria e no preparo emocional',
      ],
    },
  },
  {
    classID: 75,
    teachersName: [ 'Jefferson Laatus' ],
    courseID: 80,
    courseColor: '#ffb600 ',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#866309', '#CC9203' ],
    nTotalLessons: 35,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728982620?h=f1b9a851d1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-29_09-07-58_GRatualizado_GerenciamentodeRisco_footer.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Gerenciamento de risco com técnicas e ferramentas profissionais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_1.svg',
        },
        {
          title: 'A definir limites e metas de ganhos e construir um plano para seguir',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_2.svg',
        },
        {
          title: 'A administrar as emoções e encontrar seu estilo de trade',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_3.svg',
        },
        {
          title: 'A ganhar consistência e construir um plano de evolução no mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_4.svg',
        },
        {
          title: 'A adquirir experiência com um dos mais renomados traders do Brasil',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Ícones_5.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma experiência necessária e extremamente relevante para a sua maturidade e evolução como trader',
          'Vida real: situações verídicas de gerenciamento sob a ótica de quem entende do assunto',
          'Ensinamentos do mercado para você se manter “vivo” por mais tempo',
          'Soluções 360 para tudo que impede o trader de ter ganhos mais consistentes',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um apanhado de bons resultados e de dados otimistas sobre o mercado',
          'A ilusão de que viver do mercado financeiro é algo fácil ou simples',
          'Um caminho para quem não está disposto a dedicar tempo e esforço para evoluir',
          'Semelhante a qualquer outro curso de mesma temática existente no mercado',
        ],
      },
    },
    infoSection: {
      title: 'O método de Gerenciamento de Risco',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Profundidade.jpg',
          title: 'Profundidade',
          description: 'Em 35 aulas recheadas de conteúdo, Laatus compartilha muito da sua experiência e a metodologia  que utiliza para operar. É garantia de um aprendizado sólido.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Realidade.jpg',
          title: 'Realidade',
          description: 'Teoria aplicada na prática e troca de conhecimento com casos reais, que só um professor com mais de 22 anos de experiência na área pode oferecer',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Gerenciamento/Adaptacao.jpg',
          title: 'Adaptação',
          description: 'O curso vai te ensinar a caminhar com as próprias pernas, entregando conhecimento, antecipando dificuldades e tirando dúvidas para ajudar você a chegar mais rápido aos seus objetivos.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Gerenciamento de Risco vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'No mundo do trading, os ganhos podem ser ilimitados, mas as perdas também. Aprenda com quem vive o dia a dia do mercado, conheça os segredos para construir uma rotina consistente e transforme a maneira como você opera.',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Foco na teoria e no preparo emocional',
      ],
    },
  },
  {
    classID: 66,
    teachersName: [ '' ],
    courseID: 84,
    courseColor: '#FF603B',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#A9351D', '#FC7632' ],
    nTotalLessons: 36,
    nLives: 1,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/757678034?h=c3d5276ada&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-08-01_12-08-00_LOGO_MBABRANCO.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Como criar o projeto de um criptoativo, desde a programação de smart contracts até a finalização de um whitepaper',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/icones/MBA_Aprender_01_Como_Criar.svg',
        },
        {
          title: 'O que preciso saber sobre regulação financeira para empreender com segurança na criptoeconomia',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/icones/MBA_Aprender_02_Regulacao_Financeira.svg',
        },
        {
          title: 'Como analisar projetos de cripto, blockchain e web3 por meio de métricas e critérios definidos por especialistas',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/icones/MBA_Aprender_03_Como_Analisar.svg',
        },
        {
          title: '9 Regras de Ouro dos Tapscott e o Trilema cripto do Vitalik',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/icones/MBA_Aprender_04_Regras_Ouro.svg',
        },
        {
          title: 'Empreender e fazer negócios digitais na web 2 e 3.0',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/icones/MBA_Aprender_05_empreender.svg',
        },
        {
          title: 'Quais são as principais tendências da criptoeconomia, seu potencial e seus limites',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/icones/MBA_Aprender_06_Principais_Tendencias.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Qualificação do conteúdo que circula em excesso na internet',
          'A união dos melhores especialistas, profissionais e acadêmicos na área de criptofinanças no Brasil',
          'Uma análise de tendências e oportunidades utilizando Blockchain e web3',
          'Formação de especialistas em criptofinanças para aplicação em negócios',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma formação para traders de criptomoedas',
          'Uma série de vídeos gravados sem conexão',
          'Somente um curso que ensina como investir',
          'Um método para ganhar dinheiro a curto prazo',
        ],
      },
    },
    infoSection: {
      title: 'O método de Criptofinanças IBMEC',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/metodo/aprenda_com_melhores.jpg',
          title: 'Aprenda com os melhores',
          description: 'Juntamos os principais profissionais, especialistas e acadêmicos do cripto mercado no Brasil para entregar a você um pacote de conhecimento completo.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/metodo/conteudo_pratico.jpg',
          title: 'Conteúdo Prático',
          description: 'Desenvolva suas habilidades aplicando conhecimento em projetos e situações reais, com as quais será ou já foi confrontado em sua carreira.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MBA/metodo/assuntos_interconectados.jpg',
          title: 'Assuntos interconectados',
          description: 'Por meio de aulas com conteúdo interligado, você terá uma compreensão de todo o potencial prático dessas novas tecnologias.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o MBA - Cripto Finanças vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'O MBA em Criptofinanças é a resposta que faltava para preparar profissionais e empresas para o futuro do sistema financeiro. Aprenda a investir, empreender ou regular qualquer atividade relacionada à criptoeconomia de maneira profissional.',
      information: [
        'Certificado de conclusão',
        '60 dias de acesso gratuito ao Vector Pro *',
        'Disponível também na versão mobile',
      ],
    },
    isMBA: true,
  },
  {
    classID: 65,
    teachersName: [ 'André Hanna' ],
    courseID: 81,
    courseColor: '#B51545',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    moduleFontColor: '#FFFFFFFF',
    bkgColor: [ '#740E39', '#B51545' ],
    nTotalLessons: 14,
    nLives: 3,
    level: 'Iniciante',
    levelId: 0,
    nPortion: 18,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    coverVideo: 'https://player.vimeo.com/video/735532142?h=107c548d6a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-08-01_03-08-18_LogoFundamentosdoTrader-02.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Dados fundamentais sobre microestrutura de mercado e macrofinanças',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_1.svg',
        },
        {
          title: 'Quem são os participantes do mercado e como cada um deles atua',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_2.svg',
        },
        {
          title: 'Como ler e entender o mercado para além dos gráficos e do fluxo',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_3.svg',
        },
        {
          title: 'Quais informações devem ser levadas em conta na hora das análises',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_4.svg',
        },
        {
          title: 'A desenvolver suas próprias análises com base em conceitos financeiros fundamentais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_5.svg',
        },
        {
          title: 'Que é possível aplicar os conceitos aprendidos em diferentes tipos de operação, como long & short e scalping',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_6.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Para quem tem a mente aberta para absorver uma grande quantidade de informações',
          'A oportunidade para fugir do superficial e se aprofundar em conhecimentos essenciais sobre o mercado',
          'Um conjunto de conceitos com curadoria exclusiva do prof. Hanna, uma das maiores referências em mercado financeiro no Brasil',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras prontas para aplicar sem esforço',
          'Estratégias para operar day trade e swing trade',
          'Mais um curso que promete fazer de você um profissional em poucas horas',
          'Para aqueles que buscam atalhos ou caminhos fáceis para ganhar dinheiro',
        ],
      },
    },
    infoSection: {
      title: 'O método de Fundamentos do Trader',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/metodo/versatilidade.jpg',
          title: 'Versatilidade',
          description: 'Ao compreender conceitos fundamentais, o aluno passará a utilizar as estruturas do mercado para desenvolver análises fundamentadas e baseadas em dados concretos.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/metodo/objetividade.jpg',
          title: 'Objetividade',
          description: 'Um curso direto ao ponto, que direciona o foco, o esforço e o estudo dos alunos. Apresenta uma metodologia prática antes restrita a cursos de longa duração.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/metodo/solidez.jpg',
          title: 'Solidez',
          description: 'Não espere algo rápido e fácil: aprenda com o prof. Hanna como construir uma base sólida de conhecimentos com utilidade a longo prazo.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que os Fundamentos do Trader vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: `Uma oportunidade única de aprender sobre conceitos fundamentais do mercado com uma das maiores referências na área. Aproveite este presente da ${getNameByProduct()} e da Nelogica e aprimore seus conhecimentos para atingir um novo patamar de conhecimento. Não perca a chance de mudar sua percepção sobre o mercado para sempre.`,
      information: [
        'Valor: gratuito',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Foco total na prática e na troca de experiências entre os participantes e o professor',
      ],
    },
  },
  {
    classID: 68,
    teachersName: [ 'André Mombach' ],
    courseID: 87,
    courseColor: '#2F84C9',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#343491', '#2F84C9' ],
    nTotalLessons: 5,
    level: 'Iniciante',
    levelId: 0,
    nPortion: 18,
    nLives: 2,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    coverVideo: 'https://player.vimeo.com/video/757416670?h=d520703852&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/logo/Logo_RODAPE_FLUTUANTE.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: 'Aprenda na prática a automatizar suas operações. Crie seu próprio robô para operar com mais rapidez e segurança.',
      images: [
        {
          title: 'Usar a ferramenta Automação de Estratégias no ProfitPro',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon01.svg',
        },
        {
          title: 'Criar uma estratégia automatizada do zero',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon02.svg',
        },
        {
          title: 'Ganhar tempo na sua rotina de trader',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon03.svg',
        },
        {
          title: 'Aumentar a consistência nos seus resultados',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon04.svg',
        },
        {
          title: 'Operar de forma mais racional e menos emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon05.svg',
        },
        {
          title: 'Ganhar segurança, diminuir risco e erros',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon06.svg',
        },
        {
          title: 'Conhecer as funcionalidades da ferramenta “Editor de Estratégias”',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/icones/icon07.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um guia de como usar a ferramenta “Automação de Estratégias” do ProfitPro',
          'Um curso prático com  dicas valiosas para você começar a automatizar suas estratégias',
          'Um passo a passo para você ter segurança na hora da automação',
          'Uma maneira de você entender que programar estratégias não é um bicho de sete cabeças',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um curso de programação',
          'Uma promessa de ganhos fáceis sem precisar de planejamento e estudo',
          'Um guia de como ter estratégias prontas',
        ],
      },
    },
    infoSection: {
      title: 'O método de Automação de Estratégias no Profit',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/metodo/aprenda.png',
          title: 'Aprenda com quem faz',
          description: 'O curso foi 100% criado por quem desenvolve o sistema de automação de estratégias e domina a plataforma.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/metodo/objetividade.png',
          title: 'Objetividade',
          description: 'Conteúdo direto ao ponto para você não perder tempo.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/AEP/metodo/conhecimento.png',
          title: 'Conhecimento Aplicado',
          description: 'Aprenda na prática como a automação de estratégias funciona.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'GANHE TEMPO E CONSISTÊNCIA SEM PERDER NENHUM TRADE',
      description: 'Aprenda agora a automatizar suas operações e ganhe tempo na sua rotina de trader. Comece a operar de maneira 100% racional, garantindo eficiência e consistência nos seus ganhos.',
      information: [
        '5 aulas direto ao ponto',
        '2 Lives com o professor nos dias 13/10 e 20/10 às 19h',
        'Questões práticas para testar seu aprendizado',
        'Curso 100% gratuito',
        'Certificado de conclusão',
        'Acesso disponível por 1 ano',
      ],
    },
  },
  { // Crypto Trader
    classID: 71,
    teachersName: [ '' ],
    courseID: 86,
    courseColor: '#03dfe5',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#03dfe5', '#0cb2ed' ],
    nTotalLessons: 13,
    level: 'Iniciante',
    levelId: 0,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'A história do surgimento do Bitcoin e a revolução cripto no mercado financeiro.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon01.svg',
        },
        {
          title: 'Como diferenciar projetos confiáveis de esquemas fraudulentos.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon02.svg',
        },
        {
          title: 'O que é a rede blockchain, a tecnologia por trás das criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon03.svg',
        },
        {
          title: 'Como criar uma conta em uma corretora de criptomoedas e uma wallet digital.” ',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon04.svg',
        },
        {
          title: 'Quais as diferenças e as semelhanças entre o mercado tradicional e o mundo cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon05.svg',
        },
        {
          title: 'Como funciona a declaração de criptomoedas no Imposto de Renda.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon06.svg',
        },
        {
          title: 'Como usar a plataforma Vector para operar como um trader profissional de criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon07.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um curso introdutório para você iniciar a sua jornada de cripto trader',
          'Sua porta de entrada para operar cripto moedas com inteligência',
          'Aulas com especialistas em cripto do Brasil e do mundo',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma fórmula de ganhos rápidos e garantidos',
          'Um passo a passo para ganhar dinheiro fácil',
          'Um curso com foco apenas na teoria',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/objetividade.png',
          title: 'Objetividade',
          description: 'Aulas curtas e objetivas para quem está começando mas busca conteúdo de qualidade.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/conhecimento.png',
          title: 'Conhecimento',
          description: 'Aprenda a diferenciar projetos confiáveis de esquemas fraudulentos.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/experiencia.png',
          title: 'Experiência',
          description: 'Participação de especialistas, empresários, contadores e investidores do Brasil e do mundo.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Entre para a revolução que surgiu com a rede blockchain. O futuro do dinheiro está ao seu alcance. Mas não espere demais. Agarre essa oportunidade de dominar o mercado cripto sem gastar nada!',
      information: [
        'Curso gratuito',
        'Certificado de conclusão',
        'Disponível na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcasts exclusivos',
      ],
    },
  },
  { // Crypto Options
    classID: 69,
    teachersName: [ 'Isac Costa' ],
    courseID: 88,
    courseColor: '#c6a97d',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#c6a97d', '#b88b4a' ],
    freeCourse: true,
    level: 'Iniciante',
    levelId: 0,
    nTotalLessons: 10,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/logo/BANNER_PRINCIPAL-HOME_PREENCHIDO.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Como dar os primeiros passos no Mercado de Opções em Cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon01.svg',
        },
        {
          title: 'Os conceitos-chave para operar Opções em Cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon02.svg',
        },
        {
          title: 'Funcionamento de Opções em Cripto como Expiry series, Regulação e Grade de Opções.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon03.svg',
        },
        {
          title: '8 Princípios Estratégicos para operar Opções em Cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon04.svg',
        },
        {
          title: 'Indicadores e setups para começar a operar Opções em Bitcoin e outras altcoins.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon05.svg',
        },
        {
          title: 'Como usar a plataforma Vector de forma prática.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon06.svg',
        },
        {
          title: 'Integração com a Exchange Deribit.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon07.svg',
        },
        {
          title: 'Calls, puts e estratégias no Vector.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon08.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma introdução especializada sobre trading de Opções em criptomoedas ',
          'Estudo dos conceitos-chave para operar no mercado de Opções em cripto',
          'Um aprofundamento nas principais ferramentas para a tomada de decisões no trading em Opcões ',
          'Série de aulas com experts para definir as melhores estratégias para o trading',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras prontas e sem fundamento',
          'Um atalho para ganhar dinheiro fácil',
          'Um curso com foco apenas na teoria',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/metodos/objetividade.png',
          title: 'Objetividade',
          description: 'Aulas diretas e focadas para quem busca aproveitar novas oportunidades de operação.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/metodos/inovacao.png',
          title: 'Inovação',
          description: 'Aprendizagem com experts em um mercado revolucionário e que está criando novas possibilidades a nível global.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/metodos/pratica.png',
          title: 'Prática',
          description: 'Descubra o passo a passo para negociar criptoativos de forma efetiva, bem informada e adaptada aos diferentes cenários do mercado.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Diversifique suas operações e saiba tudo sobre esse mercado revolucionário com a ajuda de grandes especialistas. Não perca essa oportunidade, o curso é gratuito e por tempo limitado!',
      information: [
        'Curso 100% gratuito',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcast exclusivos com especialistas',
      ],
    },
  },
  { // Princípios da Análise Técnica
    classID: 70,
    teachersName: [ 'Fábio Figueiredo (Vlad)' ],
    courseID: 89,
    courseColor: '#e5a32d',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#e5a32d', '#e8782d' ],
    nTotalLessons: 3,
    freeCourse: true,
    level: 'Iniciante',
    levelId: 0,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/logo/Logo_RODAPE_FLUTUANTE.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Os perigos da análise técnica clássica',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon01.png',
        },
        {
          title: 'Como os vieses emocionais impactam suas operações',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon02.png',
        },
        {
          title: 'Como tomar decisões objetivas na hora do trade',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon03.png',
        },
        {
          title: 'Quais os resultados da análise técnica objetiva',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon04.png',
        },
        {
          title: 'Os perigos da análise técnica clássica',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon01.png',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma introdução sobre a análise técnica objetiva',
          'Treinamento que alerta sobre os perigos da análise técnica clássica',
          'Conjunto de conceitos feitos com a curadoria de um dos maiores experts do mercado',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras para aplicar em qualquer situação de mercado',
          'Uma nova estratégia pronta para operar em day trade',
          'Um treinamento para quem quer ganhar dinheiros em esforço',
        ],
      },
    },
    infoSection: {
      title: 'O método de Fundamentos do Trader',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/metodo/dinamismo.png',
          title: 'Dinamismo',
          description: 'Poucas aulas e de curta duração, com um conteúdo rico em pesquisas e fundamentos para ajudar você trader e investidor.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/metodo/experiencia.png',
          title: 'Experiência',
          description: 'Material desenvolvido pelo maior especialista em análise técnica do Brasil, Fábio Figueiredo (Vlad).',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/metodo/compromisso.png',
          title: 'Compromisso',
          description: 'Vlad desconstrói alguns dos principais tabus do mercado financeiro com o apoio de indicadores e fundamentos técnicos.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que os Fundamentos do Trader vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer entender os princípios da análise técnica objetiva? ',
      description: 'Matricule-se e descubra como aperfeiçoar uma das principais técnicas usada pelos traders de sucesso. Essa é uma oportunidade  única para você transformar o jeito como enxerga o mercado e aprender a construir seu próprio método de análise baseado em indicadores e fundamentos técnicos.',
      information: [
        '100% gratuito',
        'Certificado de conclusão',
        'Disponível na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcast exclusivos',
      ],
    },
  },
  { // InteligenciaEmocional
    classID: 72,
    teachersName: [ 'Luana Schneider' ],
    courseID: 90,
    courseColor: '#3f9d51',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#3f9c51', '#42cd56' ],
    nLives: 3,
    nTotalLessons: 3,
    level: 'Iniciante',
    levelId: 0,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/logo/Logo_RODAPE_FLUTUANTE.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'O que é inteligência emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon01.svg',
        },
        {
          title: 'O tripé dos ganhos consistentes no trading',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon02.svg',
        },
        {
          title: 'O “paracetamol” do controle emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon03.svg',
        },
        {
          title: 'Compreender o real significado de “perder dinheiro” ',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon04.svg',
        },
        {
          title: 'Ganhar segurança e diminuir riscos e erros',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon05.svg',
        },
        {
          title: 'Como alcançar o autocontrole emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon06.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um conjunto de dicas valiosas para você aprender a operar com inteligência emocional',
          'Método para quem quer aprender a ter autocontrole e consistência',
          'A oportunidade de aprender a fugir das armadilhas',
          'Conhecimento fundamental para além do lado técnico do trading',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conteúdo de autoajuda',
          'Aulas com temática motivacional para traders',
          'Soluções mágicas para você aplicar sem esforço',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//InteligenciaEmocional/metodos/experiencia.png',
          title: 'Experiência',
          description: 'Aprenda com a maior especialista em inteligência emocional e reprogramação mental para trading do Brasil.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//InteligenciaEmocional/metodos/autoconhecimento.png',
          title: 'Autoconhecimento',
          description: 'Existe muito conteúdo disponível que ensina a operar. Mas o autoconhecimento pode ser a chave da virada na sua jornada de trader.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//InteligenciaEmocional/metodos/conteudo-pratico.png',
          title: 'Conteúdo prático',
          description: 'Descubra como obter consistência com dicas para além da análise do mercado. Conheça estratégias mentais que complementam o seu conhecimento de trading.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer dominar o trading para além da técnica operacional? ',
      description: 'Aprenda a alcançar o autocontrole para atingir a consistência no trading. Entenda de uma vez por todas como a inteligência emocional pode mudar seus resultados.',
      information: [
        'Curso 100% gratuito',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Lives gravadas para você rever',
      ],
    },
  },
  { // Como comprar um imóvel morando de aluguel
    classID: 85,
    teachersName: [ 'Rafael Paschoarelli' ],
    courseID: 91,
    courseColor: '#3f9d51',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#3f9e51', '#3ab54e' ],
    nTotalLessons: 7,
    level: 'Iniciante',
    levelId: 0,
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/logo/comprando_um_imovel_morando_de_aluguel.svg',
    coverVideo: 'https://player.vimeo.com/video/774341038?h=f45755fa87&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Quais fatores você precisa levar em conta na escolha entre comprar um imóvel financiado ou continuar morando de aluguel.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon01.png',
        },
        {
          title: 'As principais variáveis financeiras que você deve analisar antes de tomar uma decisão.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon02.png',
        },
        {
          title: 'Como calcular a relação entre o aluguel e o valor do imóvel.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon03.png',
        },
        {
          title: 'Descubra o quanto da prestação do financiamento realmente é utilizada para reduzir a sua dívida.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon04.png',
        },
        {
          title: 'Entenda o que compõe cada parcela do financiamento de um imóvel antes de tomar uma decisão.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon05.png',
        },
        {
          title: 'Compreenda a diferença entre amortização e juros.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon06.png',
        },
        {
          title: 'Conheça todos os elementos que fazem parte das prestações.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/icones/icon07.png',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um guia para você entender como um economista decide entre comprar e alugar imóveis.',
          'A oportunidade de aprender a analisar com objetividade o que é melhor para o seu perfil na hora de decidir entre comprar ou alugar.',
          'Para quem busca respostas para os dilemas financeiros relacionados ao sonho da casa própria.',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um financiamento de imóveis.',
          'A solução para a compra do seu imóvel.',
          'Uma promessa de que você terá sua casa própria.',
        ],
      },
    },
    infoSection: {
      title: 'O método Como comprar um imóvel morando de aluguel',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/metodos/objetividade.png',
          title: 'Objetividade',
          description: 'Aulas objetivas elaboradas para você aprender como solucionar os dilemas relacionados ao sonho da casa própria.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/metodos/solidez.png',
          title: 'Solidez',
          description: 'Aprenda a fazer uma análise baseada em dados com Rafael Paschoarelli, professor de finanças da USP e fundador do Comdinheiro.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/ComprandoUmImovelMorandoDeAluguel/metodos/versatilidade.png',
          title: 'Versatilidade',
          description: 'Conteúdo que se adapta ao seu perfil de investidor, sem oferecer uma resposta única e simples para questões complexas.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Na dúvida entre comprar e alugar a sua casa dos sonhos?',
      description: 'A compra da casa própria é o maior investimento que grande parte dos brasileiros faz na vida. Descubra o que os bancos não dizem sobre financiamento imobiliário e aprenda você mesmo a escolher a melhor opção para o seu perfil. Ganhe 30 dias de acesso ao Guia de Finanças Pessoais do Comdinheiro e faça todas as simulações de financiamento que aprendeu no curso!',
      information: [
        'Início das aulas: 28/11/22. Disponíveis por 6 meses.',
        'Resumos das aulas para você estudar',
        'Podcasts exclusivos',
        'Questões para testar o aprendizado',
        'Certificado de conclusão',
        '30 dias grátis de acesso ao Guia de Finanças Pessoais do Comdinheiro',
      ],
    },
  },
  { // Mercado Futuro
    classID: 86,
    teachersName: [ 'Marco Prado', 'Raquel Vieira' ],
    courseID: 92,
    courseColor: '#8741a5',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#8741a5', '#704293' ],
    nTotalLessons: 32,
    level: 'Iniciante',
    levelId: 0,
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/logo/mercado_futuro1.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Os conceitos-chave do sistema Blockchain.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon01.svg',
        },
        {
          title: 'A história do dinheiro e o surgimento do Bitcoin.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon02.svg',
        },
        {
          title: 'As diferenças entre mercado futuro tradicional e de criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon03.svg',
        },
        {
          title: 'Macroeconomia e criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon04.svg',
        },
        {
          title: 'Análise gráfica e de fluxo.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon05.svg',
        },
        {
          title: 'Equilíbrio emocional na hora de operar.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon06.svg',
        },
        {
          title: 'O Mercado Futuro das criptomoedas na prática.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/icones/icon07.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um ensino teórico e prático para aprender no seu ritmo.',
          'A oportunidade de colocar em prática os fundamentos do Mercado futuro em cripto.',
          'Um apontamento dos riscos e recompensas que o mercado oferece.',
          'Conteúdo verificado por experts no mercado tradicional e de criptomoedas.',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras prontas e sem fundamento.',
          'Um atalho para ganhar dinheiro fácil.',
          'Um curso com foco apenas na teoria.',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado Futuro',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/metodos/experiencia.png',
          title: 'Experiência',
          description: 'O conhecimento de décadas de trabalho no mercado e uma profunda compreensão das criptomoedas vão acompanhar você ao longo do curso.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/metodos/inovacao.png',
          title: 'Inovação',
          description: 'O Mercado Futuro das criptomoedas abre novas oportunidades de operação. Esse curso apresenta as ferramentas para você alcançar os melhores resultados.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/MercadoFuturo/metodos/pratica.png',
          title: 'Prática',
          description: 'Sua aprendizagem fica mais clara quando você mesmo coloca em prática. Receba o acompanhamento necessário e 90 dias grátis da plataforma Vector para operar criptomoedas no Mercado Futuro.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Faça parte do Mercado Futuro',
      description: 'Fique por dentro das novas modalidades do mercado e amplie suas possibilidades de retorno, além de diversificar seus conhecimentos para operar. Aproveite agora e comece a aprender junto com especialistas.',
      information: [
        'Certificado de conclusão',
        'Conteúdos em formato de vídeos, ebooks e podcasts ',
        'Disponível também na versão mobile',
        'Faça perguntas diretamente aos professores',
      ],
    },
  },
  { // Crypto Options
    classID: 78,
    teachersName: [ 'Isac Costa' ],
    courseID: 88,
    courseColor: '#c6a97d',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#c6a97d', '#b88b4a' ],
    freeCourse: true,
    level: 'Iniciante',
    levelId: 0,
    nTotalLessons: 10,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/logo/BANNER_PRINCIPAL-HOME_PREENCHIDO.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Como dar os primeiros passos no Mercado de Opções em Cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon01.svg',
        },
        {
          title: 'Os conceitos-chave para operar Opções em Cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon02.svg',
        },
        {
          title: 'Funcionamento de Opções em Cripto como Expiry series, Regulação e Grade de Opções.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon03.svg',
        },
        {
          title: '8 Princípios Estratégicos para operar Opções em Cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon04.svg',
        },
        {
          title: 'Indicadores e setups para começar a operar Opções em Bitcoin e outras altcoins.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon05.svg',
        },
        {
          title: 'Como usar a plataforma Vector de forma prática.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon06.svg',
        },
        {
          title: 'Integração com a Exchange Deribit.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon07.svg',
        },
        {
          title: 'Calls, puts e estratégias no Vector.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/icones/icon08.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma introdução especializada sobre trading de Opções em criptomoedas ',
          'Estudo dos conceitos-chave para operar no mercado de Opções em cripto',
          'Um aprofundamento nas principais ferramentas para a tomada de decisões no trading em Opcões ',
          'Série de aulas com experts para definir as melhores estratégias para o trading',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras prontas e sem fundamento',
          'Um atalho para ganhar dinheiro fácil',
          'Um curso com foco apenas na teoria',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/metodos/objetividade.png',
          title: 'Objetividade',
          description: 'Aulas diretas e focadas para quem busca aproveitar novas oportunidades de operação.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/metodos/inovacao.png',
          title: 'Inovação',
          description: 'Aprendizagem com experts em um mercado revolucionário e que está criando novas possibilidades a nível global.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CryptoOptions/metodos/pratica.png',
          title: 'Prática',
          description: 'Descubra o passo a passo para negociar criptoativos de forma efetiva, bem informada e adaptada aos diferentes cenários do mercado.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Diversifique suas operações e saiba tudo sobre esse mercado revolucionário com a ajuda de grandes especialistas. Não perca essa oportunidade, o curso é gratuito e por tempo limitado!',
      information: [
        'Curso 100% gratuito',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcast exclusivos com especialistas',
      ],
    },
  },
  {
    classID: 79,
    teachersName: [ 'André Hanna' ],
    courseID: 81,
    courseColor: '#B51545',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    moduleFontColor: '#FFFFFFFF',
    bkgColor: [ '#740E39', '#B51545' ],
    nTotalLessons: 14,
    nLives: 3,
    level: 'Iniciante',
    levelId: 0,
    nPortion: 18,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    coverVideo: 'https://player.vimeo.com/video/735532142?h=107c548d6a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-08-01_03-08-18_LogoFundamentosdoTrader-02.svg.svg',
    campaignBenefit: '',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Dados fundamentais sobre microestrutura de mercado e macrofinanças',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_1.svg',
        },
        {
          title: 'Quem são os participantes do mercado e como cada um deles atua',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_2.svg',
        },
        {
          title: 'Como ler e entender o mercado para além dos gráficos e do fluxo',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_3.svg',
        },
        {
          title: 'Quais informações devem ser levadas em conta na hora das análises',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_4.svg',
        },
        {
          title: 'A desenvolver suas próprias análises com base em conceitos financeiros fundamentais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_5.svg',
        },
        {
          title: 'Que é possível aplicar os conceitos aprendidos em diferentes tipos de operação, como long & short e scalping',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/icones/icones_fundamentos_6.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Para quem tem a mente aberta para absorver uma grande quantidade de informações',
          'A oportunidade para fugir do superficial e se aprofundar em conhecimentos essenciais sobre o mercado',
          'Um conjunto de conceitos com curadoria exclusiva do prof. Hanna, uma das maiores referências em mercado financeiro no Brasil',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras prontas para aplicar sem esforço',
          'Estratégias para operar day trade e swing trade',
          'Mais um curso que promete fazer de você um profissional em poucas horas',
          'Para aqueles que buscam atalhos ou caminhos fáceis para ganhar dinheiro',
        ],
      },
    },
    infoSection: {
      title: 'O método de Fundamentos do Trader',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/metodo/versatilidade.jpg',
          title: 'Versatilidade',
          description: 'Ao compreender conceitos fundamentais, o aluno passará a utilizar as estruturas do mercado para desenvolver análises fundamentadas e baseadas em dados concretos.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/metodo/objetividade.jpg',
          title: 'Objetividade',
          description: 'Um curso direto ao ponto, que direciona o foco, o esforço e o estudo dos alunos. Apresenta uma metodologia prática antes restrita a cursos de longa duração.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/Fundamentos/metodo/solidez.jpg',
          title: 'Solidez',
          description: 'Não espere algo rápido e fácil: aprenda com o prof. Hanna como construir uma base sólida de conhecimentos com utilidade a longo prazo.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que os Fundamentos do Trader vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: `Uma oportunidade única de aprender sobre conceitos fundamentais do mercado com uma das maiores referências na área. Aproveite este presente da ${getNameByProduct()} e da Nelogica e aprimore seus conhecimentos para atingir um novo patamar de conhecimento. Não perca a chance de mudar sua percepção sobre o mercado para sempre.`,
      information: [
        'Valor: gratuito',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Foco total na prática e na troca de experiências entre os participantes e o professor',
      ],
    },
  },
  { // Princípios da Análise Técnica
    classID: 80,
    teachersName: [ 'Fábio Figueiredo (Vlad)' ],
    courseID: 89,
    courseColor: '#e5a32d',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#e5a32d', '#e8782d' ],
    nTotalLessons: 3,
    freeCourse: true,
    level: 'Iniciante',
    levelId: 0,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/logo/Logo_RODAPE_FLUTUANTE.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Os perigos da análise técnica clássica',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon01.png',
        },
        {
          title: 'Como os vieses emocionais impactam suas operações',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon02.png',
        },
        {
          title: 'Como tomar decisões objetivas na hora do trade',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon03.png',
        },
        {
          title: 'Quais os resultados da análise técnica objetiva',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon04.png',
        },
        {
          title: 'Os perigos da análise técnica clássica',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/icones/icon01.png',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Uma introdução sobre a análise técnica objetiva',
          'Treinamento que alerta sobre os perigos da análise técnica clássica',
          'Conjunto de conceitos feitos com a curadoria de um dos maiores experts do mercado',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conjunto de regras para aplicar em qualquer situação de mercado',
          'Uma nova estratégia pronta para operar em day trade',
          'Um treinamento para quem quer ganhar dinheiros em esforço',
        ],
      },
    },
    infoSection: {
      title: 'O método de Fundamentos do Trader',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/metodo/dinamismo.png',
          title: 'Dinamismo',
          description: 'Poucas aulas e de curta duração, com um conteúdo rico em pesquisas e fundamentos para ajudar você trader e investidor.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/metodo/experiencia.png',
          title: 'Experiência',
          description: 'Material desenvolvido pelo maior especialista em análise técnica do Brasil, Fábio Figueiredo (Vlad).',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/PrincipiosAnaliseTecnica/metodo/compromisso.png',
          title: 'Compromisso',
          description: 'Vlad desconstrói alguns dos principais tabus do mercado financeiro com o apoio de indicadores e fundamentos técnicos.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que os Fundamentos do Trader vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer entender os princípios da análise técnica objetiva? ',
      description: 'Matricule-se e descubra como aperfeiçoar uma das principais técnicas usada pelos traders de sucesso. Essa é uma oportunidade  única para você transformar o jeito como enxerga o mercado e aprender a construir seu próprio método de análise baseado em indicadores e fundamentos técnicos.',
      information: [
        '100% gratuito',
        'Certificado de conclusão',
        'Disponível na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcast exclusivos',
      ],
    },
  },
  {
    classID: 48,
    teachersName: [ 'Wilson Neto' ],
    courseID: 75,
    courseColor: '#2b9ed8',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#024585', '#2B7EB1' ],
    nTotalLessons: 15,
    level: 'Iniciante',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728221590?h=7ac0c497f2',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-07-26_10-07-02_Logo_NTG_footer.svg.svg',
    campaignBenefit: '',
    bannerSection: {
      title: 'Faça o dinheiro trabalhar para você e conquiste sua ',
      titleStrong: 'independência financeira',
      informations: [
        {
          icon: 'ia-cap-ic',
          text: '<strong>15</strong> videoaulas dinâmicas, distribuídas em <strong>5</strong> módulos ao longo de <strong>5</strong> semanas. Os módulos serão liberados às segundas-feiras, <strong>a partir de 28/11</strong>',
        },
        {
          icon: 'ia-microphone-ic',
          text: '<strong>6</strong> podcasts com convidados do mercado financeiro',
        },
        {
          icon: 'ia-certificate-ic',
          text: '<strong>15</strong> resumos das aulas no formato de textos',
        },
        {
          icon: 'ic-check',
          text: 'Questões práticas para testar seu aprendizado',
        },
      ],
    },
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Entenda as vantagens e os riscos de operar minicontratos de índice e dólar',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_1.svg',
        },
        {
          title: 'Domine os conceitos de margem de garantia e alavancagem',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_2.svg',
        },
        {
          title: 'A prática do mercado, por meio de aulas dinâmicas e cenários reais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_3.svg',
        },
        {
          title: 'Proteja seu capital com técnicas de gerenciamento de risco profissional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_4.svg',
        },
        {
          title: 'Estude as melhores técnicas e se destaque entre os grandes players',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_5.svg',
        },
        {
          title: 'Aprenda a ganhar com os movimentos do mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_6.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um conjunto de técnicas validadas por grandes profissionais',
          'Uma combinação vencedora de teoria e prática',
          'A melhor forma de aprender a operar com consistência',
          'A grande oportunidade para elevar seus trades a outro patamar',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma fórmula de ganhos rápidos e garantidos',
          'Um passo a passo para ganhar dinheiro fácil',
          'Um curso sem aplicação prática',
          'Para quem não está disposto a correr riscos',
        ],
      },
    },
    infoSection: {
      title: 'O método NTG',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Teoria_Mais_Pratica.jpg',
          title: 'Teoria + prática',
          description: 'Aprenda os principais conceitos do mercado e coloque em prática entendendo como, por que e o que está sendo feito.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Experiencia.jpg',
          title: 'Experiência',
          description: 'Aprenda com Wilson Neto, trader referência no mercado com mais de duas décadas de experiência.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Vida_Real.jpg',
          title: 'Vida real',
          description: 'Sem fórmulas fáceis e sem truques: você vai aprender os riscos e benefícios de operar em um cenário de alta performance.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que a Trilha do Gain vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Toda recompensa exige um risco. Aprenda a dominar essa e outras regras do jogo que vão mudar o seu jeito de operar. Não perca mais tempo.',
      information: [
        'Módulos liberados toda segunda-feira, a partir de 28/11',
        '6 podcasts exclusivos',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Questões práticas para testar seu aprendizado',
      ],
    },
  },
  { // InteligenciaEmocional
    classID: 81,
    teachersName: [ 'Luana Schneider' ],
    courseID: 90,
    courseColor: '#3f9d51',
    courseFontColor: '#FFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#3f9c51', '#42cd56' ],
    nLives: 3,
    nTotalLessons: 3,
    level: 'Iniciante',
    levelId: 0,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/logo/Logo_RODAPE_FLUTUANTE.svg',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'O que é inteligência emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon01.svg',
        },
        {
          title: 'O tripé dos ganhos consistentes no trading',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon02.svg',
        },
        {
          title: 'O “paracetamol” do controle emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon03.svg',
        },
        {
          title: 'Compreender o real significado de “perder dinheiro” ',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon04.svg',
        },
        {
          title: 'Ganhar segurança e diminuir riscos e erros',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon05.svg',
        },
        {
          title: 'Como alcançar o autocontrole emocional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/InteligenciaEmocional/icones/icon06.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um conjunto de dicas valiosas para você aprender a operar com inteligência emocional',
          'Método para quem quer aprender a ter autocontrole e consistência',
          'A oportunidade de aprender a fugir das armadilhas',
          'Conhecimento fundamental para além do lado técnico do trading',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Um conteúdo de autoajuda',
          'Aulas com temática motivacional para traders',
          'Soluções mágicas para você aplicar sem esforço',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//InteligenciaEmocional/metodos/experiencia.png',
          title: 'Experiência',
          description: 'Aprenda com a maior especialista em inteligência emocional e reprogramação mental para trading do Brasil.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//InteligenciaEmocional/metodos/autoconhecimento.png',
          title: 'Autoconhecimento',
          description: 'Existe muito conteúdo disponível que ensina a operar. Mas o autoconhecimento pode ser a chave da virada na sua jornada de trader.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//InteligenciaEmocional/metodos/conteudo-pratico.png',
          title: 'Conteúdo prático',
          description: 'Descubra como obter consistência com dicas para além da análise do mercado. Conheça estratégias mentais que complementam o seu conhecimento de trading.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer dominar o trading para além da técnica operacional? ',
      description: 'Aprenda a alcançar o autocontrole para atingir a consistência no trading. Entenda de uma vez por todas como a inteligência emocional pode mudar seus resultados.',
      information: [
        'Curso 100% gratuito',
        'Certificado de conclusão',
        'Disponível também na versão mobile',
        'Lives gravadas para você rever',
      ],
    },
  },
  { // Crypto Trader
    classID: 82,
    teachersName: [ '' ],
    courseID: 86,
    courseColor: '#03dfe5',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#03dfe5', '#0cb2ed' ],
    nTotalLessons: 13,
    level: 'Iniciante',
    levelId: 0,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'A história do surgimento do Bitcoin e a revolução cripto no mercado financeiro.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon01.svg',
        },
        {
          title: 'Como diferenciar projetos confiáveis de esquemas fraudulentos.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon02.svg',
        },
        {
          title: 'O que é a rede blockchain, a tecnologia por trás das criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon03.svg',
        },
        {
          title: 'Como criar uma conta em uma corretora de criptomoedas e uma wallet digital.” ',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon04.svg',
        },
        {
          title: 'Quais as diferenças e as semelhanças entre o mercado tradicional e o mundo cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon05.svg',
        },
        {
          title: 'Como funciona a declaração de criptomoedas no Imposto de Renda.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon06.svg',
        },
        {
          title: 'Como usar a plataforma Vector para operar como um trader profissional de criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon07.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um curso introdutório para você iniciar a sua jornada de cripto trader',
          'Sua porta de entrada para operar cripto moedas com inteligência',
          'Aulas com especialistas em cripto do Brasil e do mundo',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma fórmula de ganhos rápidos e garantidos',
          'Um passo a passo para ganhar dinheiro fácil',
          'Um curso com foco apenas na teoria',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/objetividade.png',
          title: 'Objetividade',
          description: 'Aulas curtas e objetivas para quem está começando mas busca conteúdo de qualidade.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/conhecimento.png',
          title: 'Conhecimento',
          description: 'Aprenda a diferenciar projetos confiáveis de esquemas fraudulentos.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/experiencia.png',
          title: 'Experiência',
          description: 'Participação de especialistas, empresários, contadores e investidores do Brasil e do mundo.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Entre para a revolução que surgiu com a rede blockchain. O futuro do dinheiro está ao seu alcance. Mas não espere demais. Agarre essa oportunidade de dominar o mercado cripto sem gastar nada!',
      information: [
        'Curso gratuito',
        'Certificado de conclusão',
        'Disponível na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcasts exclusivos',
      ],
    },
  },
  { // Crypto Trader
    classID: 87,
    teachersName: [ '' ],
    courseID: 86,
    courseColor: '#03dfe5',
    courseFontColor: '#000000',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#03dfe5', '#0cb2ed' ],
    nTotalLessons: 13,
    level: 'Iniciante',
    levelId: 0,
    freeCourse: true,
    freeCourseText: 'Curso Gratuito',
    freeCourseTextMobile: 'Gratuito',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'A história do surgimento do Bitcoin e a revolução cripto no mercado financeiro.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon01.svg',
        },
        {
          title: 'Como diferenciar projetos confiáveis de esquemas fraudulentos.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon02.svg',
        },
        {
          title: 'O que é a rede blockchain, a tecnologia por trás das criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon03.svg',
        },
        {
          title: 'Como criar uma conta em uma corretora de criptomoedas e uma wallet digital.” ',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon04.svg',
        },
        {
          title: 'Quais as diferenças e as semelhanças entre o mercado tradicional e o mundo cripto.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon05.svg',
        },
        {
          title: 'Como funciona a declaração de criptomoedas no Imposto de Renda.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon06.svg',
        },
        {
          title: 'Como usar a plataforma Vector para operar como um trader profissional de criptomoedas.',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/CriptoTrader/icones/icon07.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um curso introdutório para você iniciar a sua jornada de cripto trader',
          'Sua porta de entrada para operar cripto moedas com inteligência',
          'Aulas com especialistas em cripto do Brasil e do mundo',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma fórmula de ganhos rápidos e garantidos',
          'Um passo a passo para ganhar dinheiro fácil',
          'Um curso com foco apenas na teoria',
        ],
      },
    },
    infoSection: {
      title: 'O método do Mercado de Opções',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/objetividade.png',
          title: 'Objetividade',
          description: 'Aulas curtas e objetivas para quem está começando mas busca conteúdo de qualidade.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/conhecimento.png',
          title: 'Conhecimento',
          description: 'Aprenda a diferenciar projetos confiáveis de esquemas fraudulentos.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course//CriptoTrader/metodo/experiencia.png',
          title: 'Experiência',
          description: 'Participação de especialistas, empresários, contadores e investidores do Brasil e do mundo.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que o Viver de Renda vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Entre para a revolução que surgiu com a rede blockchain. O futuro do dinheiro está ao seu alcance. Mas não espere demais. Agarre essa oportunidade de dominar o mercado cripto sem gastar nada!',
      information: [
        'Curso gratuito',
        'Certificado de conclusão',
        'Disponível na versão mobile',
        'Questões práticas para testar seu aprendizado',
        'Ebook e Podcasts exclusivos',
      ],
    },
  },
  // TESTE
  {
    classID: 67,
    teachersName: [ 'Wilson Neto' ],
    courseID: 85,
    courseColor: '#2b9ed8',
    courseFontColor: '#FFFFFFFF',
    bannerButtonColor: '#162d3b',
    bkgColor: [ '#024585', '#2B7EB1' ],
    nTotalLessons: 15,
    nLives: 5,
    level: 'Avançado',
    nPortion: 18,
    coverVideo: 'https://player.vimeo.com/video/728221590?h=7ac0c497f2',
    logoFooter: 'https://downloadserver-cdn.nelogica.com.br/content/educational/midias/2022-08-03_12-08-48_WilsonNeto600px.png.png',
    learningSection: {
      title: 'O que você aprenderá?',
      description: '',
      images: [
        {
          title: 'Entenda as vantagens e os riscos de operar minicontratos de índice e dólar',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_1.svg',
        },
        {
          title: 'Domine os conceitos de margem de garantia e alavancagem',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_2.svg',
        },
        {
          title: 'A prática do mercado, por meio de aulas dinâmicas e cenários reais',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_3.svg',
        },
        {
          title: 'Proteja seu capital com técnicas de gerenciamento de risco profissional',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_4.svg',
        },
        {
          title: 'Estude as melhores técnicas e se destaque entre os grandes players',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_5.svg',
        },
        {
          title: 'Aprenda a ganhar com os movimentos do mercado',
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Aprender/icones_6.svg',
        },
      ],
    },
    compareSection: {
      positive: {
        title: 'O que o curso é',
        list: [
          'Um conjunto de técnicas validada por grandes profissionais',
          'Uma combinação vencedora de teoria e prática',
          'A melhor forma de aprender a operar com consistência',
          'A grande oportunidade para elevar seus trades a outro patamar',
        ],
      },
      negative: {
        title: 'O que o curso não é',
        list: [
          'Uma fórmula de ganhos rápidos e garantidos',
          'Um passo a passo para ganhar dinheiro fácil',
          'Um curso sem aplicação prática',
          'Para quem não está disposto a correr riscos',
        ],
      },
    },
    infoSection: {
      title: 'O método NTG',
      images: [
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Teoria_Mais_Pratica.jpg',
          title: 'Teoria + prática',
          description: 'Aprenda os principais conceitos do mercado e coloque em prática entendendo como, por que e o que está sendo feito.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Experiencia.jpg',
          title: 'Experiência',
          description: 'Aprenda com Wilson Neto, trader referência no mercado com mais de duas décadas de experiência.',
        },
        {
          source: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/course/OPG/Metodo/Vida_Real.jpg',
          title: 'Vida real',
          description: 'Sem fórmulas fáceis e sem truques: você vai aprender os riscos e benefícios de operar em um cenário de alta performance.',
        },
      ],
    },
    commitmentSection: {
      title: 'Compromisso de satisfação garantida',
      description: 'Como eu tenho certeza que a Trilha do Gain vai mudar sua vida e sua relação com o dinheiro, ofereço uma garantia incondicional de 7 dias a contar da data de realização da primeira aula.',
    },
    subscribeSection: {
      title: 'Quer fazer parte do mercado global?',
      description: 'Toda recompensa exige um risco. Aprenda a dominar essa e outras regras do jogo que vão mudar o seu jeito de operar. Não perca mais tempo.',
      information: [
        'Início das aulas dia 29/08',
        'Lives todas às terças, às 20h',
        '3 encontros semanais no Connect exclusivos para alunos do Na trilha do gain(Terças e Quintas) – 15:45 às 16:15 (Quartas) - 16:50 às 17:20',
        '60 dias de acesso gratuito ao Profit Pro *',
        '5 Podcasts com convidados do mercado financeiro',
        '15 resumos das aulas no formato de textos',
        'Questões práticas para testar seu aprendizado',
        'Foco total na prática e na troca de experiências entre os participantes e o professor',
        'Disponível também na versão mobile',
        'Certificado de conclusão',
      ],
    },
  },
];

export default course;
