import styled, { css } from 'styled-components';
import { product } from '../../styles/theme/themes';

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.neutral.high.pure};
`;
export const GoBackContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding-top: 4px;
  h1 {
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.medium};
    line-height: 21px;
    margin: 0 0 0 8px;
    color: ${props => props.theme.color.neutral.high.pure};
  }
`;
export const HeaderTitleText = styled.div`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: 28px;
`;
export const Container = styled.header`
  width: 100%;
  background-color: ${props => props.theme.color.background.header};
  border-bottom: ${props => product === 'INVESTACADEMY' && `1px solid ${props.theme.color.inputSearch}`};
  color: ${props => props.theme.color.neutral.low.light};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  top: 0;
  z-index: 900;
  transition: transform 1500ms ease, top .5s;
  position: ${props => props.isInvestMe ? 'fixed !important' : 'sticky'};
  height: 64px;
  border-bottom: ${props => props.theme.isWL ? `1px solid ${props.theme.color.neutral.low.dark}` : null};
  backdrop-filter: blur(15px);
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    height: 52px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    z-index: ${props => props.showHeader ? 900 : 0};
  }
`;
export const LeftHeader = styled(FlexCenter)`
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: ${props => props.hide ? 'none' : 'flex'};
  }
`;

export const CenterHeader = styled(FlexCenter)`
  display: flex;
  width: 100%;
  padding: 0 2px 0 12px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

export const RightHeader = styled(FlexCenter)`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: auto;
    max-width: ${props => props.expanded ? '375px' : '250px'};
    flex-direction: ${props => props.theme.isInvestMe ? 'row' : 'row-reverse'};
    justify-content: ${props => props.theme.isInvestMe ? 'flex-end' : 'flex-start'};
    gap: ${props => props.theme.isInvestMe ? '0' : '8px'};
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    max-width: ${(props) => {
      if (props.checkout) {
        return 'initial';
      } else {
        return props.expanded ? '100%' : '120px';
      }
    }};
  }
`;

export const HeaderItem = styled(FlexCenter)`
  padding: ${props => props.theme.isInvestMe ? '8px 0px 8px 8px' : '8px'};
  margin-right: 16px;
  width: max-content;
  position: relative;
  width: fit-content;
  border-radius: ${props => props.theme.border.radius.sm};
  transition: all 0.2s ease-in-out;
  flex-direction: ${props => (props.checkout ? 'row-reverse' : 'row')};
  cursor: pointer;
  line-height: 0px;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    background-color: ${props => props.active ? props.theme.color.background.headerItem : 'none'};
  }

  &:hover {
    ${(props) => {
      if (props.checkout === undefined) {
        if (props.theme.isWL) {
          return `
            background-color: ${props.theme.color.neutral.low.dark};
            transition: all 0.2s ease-in-out;
          `;
        }
        return `
          background-color: ${props.theme.color.neutral.low.light};
          transition: all 0.2s ease-in-out;
        `;
      }
    }};
  }

  a {
    display: flex;
    align-items: center;

    span {
      text-decoration: none;
    }
  }
  & > span,
  & > a {
    display: flex;
    color: ${props => props.theme.color.message};
    width: max-content;
    line-height: inherit;
    font-size: ${props => props.theme.font.size.xs};
    @media (max-width: ${props => props.theme.breakpoints.xl}) {
      display: ${(props) => {
        if (props.checkout) {
          return 'block';
        }
        return 'none';
      }};
      font-size: ${(props) => {
        if (props.checkout) {
          return props.theme.font.size.xxs;
        }
        return props.theme.font.size.xs;
      }};
      &.cta-link {
        display: inline;
      }
    }

    &.cta-link {
      color: ${props => props.theme.color.primary};
    }
    ${(props) => {
      if (props.checkout === undefined) {
        return 'cursor: pointer;';
      } else {
        return 'cursor: default;';
      }
    }};
  }

  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    margin-right: 0;
  }

  &.blog-link {
    span {
      margin-left: 10px;
    }
    @media (max-width: ${props => props.theme.breakpoints.xl}) {
      display: none;
    }
  }

  &.haveAccount {
    padding: 16px;
    text-align: center;
    span {
      margin: 0 0 0 4px;
    }
  }

  &.dontHaveAccount {
    padding: 16px;
    text-align: center;
    span {
      margin: 0 0 0 4px;
    }
  }

  &.downloadApp {
    padding: 0px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  span {
    margin-top: 2px;
    margin-left: 10px;
  }

  & div.new-notification::after {
    content: "";
    position: absolute;
    top: 0;
    right: -3px;
    width: 6px;
    height: 6px;
    border: 3px solid ${props => props.active ? props.theme.color.neutral.low.light : props.theme.color.neutral.low.medium};
    border-radius: 50%;
    background-color: ${props => props.theme.color.primary};

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      border-color: ${props => props.theme.color.neutral.low.dark};
    }
  }
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  height: 36px;
  margin-left: 16px;
  border-radius: ${props => props.theme.border.radius.sm};
  cursor: pointer;
  position: relative;
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: flex;
    max-width: ${props => props.expanded ? '440px' : '24px'};
    background-color: ${props => props.expanded ? props.theme.color.mobile.inputSearch : 'transparent'};
    margin-right: 8px;
    padding-left: ${props => props.expanded ? '8px' : ''};
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    background: ${props => props.expanded ? props.theme.color.background.headerItem : props.theme.color.transparent};
    margin: 0;

    ${props => props.isInvestMe && css`
        height: 32px;
        border-radius: 6px;
        position: static;
        margin: 0;
        background-color: ${props => props.expanded ? props.theme.color.inputSearch : 'transparent'};
    `};

    ${props => props.isInvestMe && props.expanded && css`
        padding-left: 8px;
    `};
  }
`;

export const CheckoutIcon = styled.div`
  margin-left: 6px;
`;

export const CancelButton = styled.span`
  color: ${props => props.theme.color.subtitle};
  font-size: ${props => props.theme.font.size.sm};
  padding-left: 16px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;
export const SandwichContainer = styled.div`
cursor: pointer;
margin: 0 16px;
@media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;

export const LogoContainer = styled.div`

`;

export const ReturnButton = styled.button`
  padding-right: 12px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
