function getValue(storage, key) {
  let value = storage.getItem(key);
  try {
    value = JSON.parse(value) || value;
  } catch {
    // do nothing
  }
  return value;
};

function setValue(storage, key, value) {
  const val = typeof value === 'string' ? value : JSON.stringify(value);
  storage.setItem(key, val);
};

export default class StorageHelper {
  static setSession(key, value) {
    setValue(window.sessionStorage, key, value);
  }

  static getSession(key) {
    return getValue(window.sessionStorage, key);
  }

  static removeSession(key) {
    window.sessionStorage.removeItem(key);
  }

  static setLocal(key, value) {
    setValue(window.localStorage, key, value);
  }

  static getLocal(key) {
    return getValue(window.localStorage, key);
  }

  static removeLocal(key) {
    window.localStorage.removeItem(key);
  }
}
