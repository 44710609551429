import { types } from './types';

export const setUserData = (newValue) => {
  const { USER } = types;

  return {
    type: USER.SUCCESS,
    payload: newValue,
  };
};

export const setUserAllData = (newValue) => {
  const { USER } = types;

  return {
    type: USER.SUCCESSALL,
    payload: newValue,
  };
};

export const setCleanUserData = () => {
  const { USER } = types;

  return {
    type: USER.LOGOFF,
    payload: null,
  };
};
