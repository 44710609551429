import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => {
    if (props.isCheckout) {
      return '32px';
    }
    return '48px';
  }};
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 32px 16px;
  }
  
`;

export const Title = styled.div`
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.size.lg};
  color: ${props => props.theme.color.loginTitle};
  text-align: center;
  padding: 8px 14px 32px 14px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.font.size.md};
    padding: 0 0 32px 0;
  }
`;

export const Subtitle = styled.div`
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.size.md};
  color: ${props => props.theme.color.primary};
  text-align: center;
  padding: 0px 14px 20px 14px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.font.size.sm};
    padding: 0 0 32px 0;
  }
`;

export const Message = styled.div`
  text-align: center;
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.color.message};
  padding: 0 0 32px;
`;

export const TokenGroup = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 9.6px;  
`;

export const TokenDigit = styled.input`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
  padding: 3px 8px 4px;
  text-align: center;
  transition: color .15s ease;
  background-color: ${props => props.theme.color.background.textArea};
  border-radius: ${props => props.theme.border.radius.sm};
  color: ${props => props.theme.color.title};
  width: 62px;
  height: 54px;
  appearance: textfield;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    background-color: ${props => props.theme.color.background.input};
    width: 62px;
    height: 54px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 50px;
  }

  /* Firefox */
  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const HintGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 0;
`;

export const Hint = styled.div`
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.color.neutral.high.medium};
  text-align: center;
`;

export const Link = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.color.primary};
  span {
    margin-left: 10px;
    font-size: ${props => props.theme.font.size.xs};
  }
`;

export const LineBreak = styled.div`
  border: 1px solid;
  border-color: ${props => props.theme.color.neutral.low.dark};
  margin: 32px 0;
`;
