import eventProducts from '../json/traderWeek.json';

const EVENT = {
  initialDate: '25/11/2024',
  finalDate: '03/12/2024',
  products: eventProducts,
};

const DiscountTypeEnum = {
  MONETARY: 1,
  PERCENTAGE: 2,
};

const EventHelper = {
  isEventActive() {
    const startDate = this.parseDate(EVENT.initialDate);
    const endDate = this.parseDate(EVENT.finalDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return today >= startDate && today <= endDate;
  },

  getCourseEventObject(courseId) {
    return EVENT.products[courseId];
  },

  getPriceWithDiscount(courseId, fullPrice) {
    const courseEventObject = this.getCourseEventObject(courseId);
    if (!courseEventObject) return fullPrice;
    if (courseEventObject?.valueAfterDiscount) return courseEventObject?.valueAfterDiscount;

    return courseEventObject.discountType === DiscountTypeEnum.MONETARY
      ? fullPrice - courseEventObject.discount
      : fullPrice * (1 - courseEventObject.discount);
  },

  parseDate(dateString) {
    return new Date(dateString.split('/').reverse().join('-'));
  },
};

export default EventHelper;
