import React, { } from 'react';
import Modal, { ModalHeaderClass } from '../Modal';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

import { Content, Title, Body, ButtonsContainer } from './styles';
import { product } from '../../../styles/theme/themes';

export const AlertModal = ({
  isShowing,
  toggle,
  title = 'global.error',
  description = 'global.error',
  confirmButton = 'global.confirm',
  cancelButton = 'global.error',
  callbackFunc = () => {},
  canBeClosed = true,
  width = '328px',
  height = '220px',
  style = {},
  fullScreenMobile = true,
  canCancel = false,
}) => {
  const { t } = useTranslation('translations');

  const closeModal = () => {
    toggle();
  };

  const confirm = () => {
    toggle();
    callbackFunc();
  };

  return (
    <Modal fullScreenMobile={fullScreenMobile} canBeClosed={canBeClosed} styleModal={{ padding: '24px', ...style }} center width={width} height={height} {...{ isShowing, toggle }}>
      <ModalHeaderClass
        canBeClosed={canBeClosed}
        containerStyle={{
          justifyContent: 'space-between',
          flexDirection: title ? 'row' : 'row-reverse',
          paddingLeft: '0',
        }}
        {...{ toggle }}
      >
        <Title>{t(title)}</Title>
      </ModalHeaderClass>
      <Body>
        <Content product={product}>
          {t(description)}
        </Content>
        <ButtonsContainer>
          {canCancel && (
            <Button
              action={closeModal}
              variant="dark"
              size="sm"
              width="92px"
            >
              {t(cancelButton)}
            </Button>
          )}
          <Button
            action={confirm}
            size="sm"
            width="92px"
          >
            {t(confirmButton)}
          </Button>
        </ButtonsContainer>
      </Body>
    </Modal>
  );
};
export default AlertModal;
