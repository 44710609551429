import { types } from './types';

export const setIsPlaylist = (newValue) => {
  const { APP } = types;

  return {
    type: APP.SET_IS_PLAYLIST,
    payload: newValue,
  };
};

export const setPlaylist = (newValue) => {
  const { APP } = types;

  return {
    type: APP.SET_PLAYLIST,
    payload: newValue,
  };
};
export const setInContent = (newValue) => {
  const { APP } = types;

  return {
    type: APP.SET_IN_CONTENT,
    payload: newValue,
  };
};
export const setScrollPosition = (newValue) => {
  const { APP } = types;

  return {
    type: APP.SET_SCROLL_POSITION,
    payload: newValue,
  };
};
export const setShowHiddenMenu = (newValue) => {
  const { APP } = types;

  return {
    type: APP.SET_SHOW_HIDDEN_SUBMENU,
    payload: newValue,
  };
};
export const setShowHeader = (newValue) => {
  const { APP } = types;

  return {
    type: APP.SET_SHOW_HEADER,
    payload: newValue,
  };
};
