import { types } from './types';

export const setLoading = (isLoading) => {
  const { LOADING } = types;
  return {
    type: LOADING.SET,
    payload: isLoading,
  };
};
export const setPartialPageLoading = (isLoading) => {
  const { PARTIALPAGELOADING } = types;

  return {
    type: PARTIALPAGELOADING.SET,
    payload: isLoading,
  };
};
