import { types } from '../actions/types';

const initialState = {
  notifications: [],
  hasNew: false,
};

const notificationsReducer = (state = initialState, action) => {
  const { NOTIFICATIONS } = types;
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATIONS.SET:
      return {
        ...state,
        notifications: payload,
        hasNew: payload && payload.some(item => item.nStatus == 0),
      };
    case NOTIFICATIONS.CLEAR:
      return {
        ...state,
        notifications: state.notifications.filter(notification =>
          notification.nNotificationID !== payload.id,
        ),
      };
    case NOTIFICATIONS.CLEAR_ALL:
      return {
        ...state,
        notifications: [],
        hasNew: false,
      };
    case NOTIFICATIONS.SET_NO_NEW:
      return {
        ...state,
        hasNew: false,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
