import StorageHelper from './StorageHelper';
import EnvHelper from './EnvHelper';
import UrlHelper from './UrlHelper';

const UserHelper = {
  isLogged() {
    const accessToken = this.getBearerToken();
    return !(accessToken === '' || !accessToken);
  },

  getLoggedUser() {
    const user = StorageHelper.getFromLocalStorage('persist:auth');
    return user;
  },

  getBearerToken() {
    const queryParams = UrlHelper.getUrlQueryParams();
    const token = EnvHelper.isPeer()
      ? StorageHelper.getFromLocalStorage('access_token')
      : queryParams.token || StorageHelper.getFromLocalStorage('access_token');
    const timestamp_token = StorageHelper.getFromLocalStorage('timestamp_token');

    if (token) {
      if (!EnvHelper.isInvestMe() && Date.now() > timestamp_token) {
        this.removeBearerToken();
      }
      return token;
    }
    return '';
  },

  removeBearerToken() {
    StorageHelper.removeFromLocalStorage('access_token');
    StorageHelper.removeFromLocalStorage('timestamp_token');
    StorageHelper.removeFromLocalStorage('expires_in');
    StorageHelper.removeFromLocalStorage('persist:root');
    StorageHelper.removeFromLocalStorage('persist:auth');
  },

  getHardwareID() {
    let hardwareID = StorageHelper.getFromLocalStorage('hardwareID');
    if (!hardwareID) {
      const array = new Uint8Array(16);
      window.crypto.getRandomValues(array);
      let hexString = '';
      for (const value of array) {
        hexString += value.toString(16).padStart(2, '0');
      }
      hardwareID = hexString;
      window.localStorage.setItem('hardwareID', hardwareID);
    }

    return hardwareID;
  },

  checkUserData(data) {
    return Object.entries(data)
      .map(([ key, value ]) => {
        switch (key) {
          case 'email':
          case 'firstName':
          case 'lastName':
          case 'dateOfBirth':
          case 'publicPlace':
          case 'number':
          case 'neighborhood':
          case 'city':
            return value && value.length > 0;
          case 'document':
          case 'postCode':
            return data.isUserBrazilian ? value && value.length > 0 : true;
          case 'state':
            return data.foundCountryDivisions ? value && value.length > 0 : true;
        }
      })
      .filter(el => el !== undefined)
      .every(el => el !== null && el);
  },

  normalizeUserObject(object) {
    const dateOfBirth = object.dateOfBirth ? object.dateOfBirth.replace(/^(\d{2}\/)(\d{2})(\d{4})$/g, '$1$2/$3').replace('00:00:00', '').trim() : '';
    const newObj = {
      username: object.firstName || '',
      usersurname: object.lastName || '',
      email: object.email || '',
      cellphone: object.cellPhone || '',
      document: object.document || '',
      dateOfBirth,
      cep: object.postCode || '',
      address: object.publicPlace || '',
      number: object.number || '',
      complement: object.addressComplement || '',
      neighborhood: object.neighborhood || '',
      city: object.city || '',
      state: object.state || '',
      nAddressCountry: `${object.nAddressCountry}` || '',
    };
    return newObj;
  },

};

export default UserHelper;
