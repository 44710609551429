import React, { useEffect, useState, useContext } from 'react';
import {
  Content,
  Title,
  Subtitle,
  Message,
  TokenGroup,
  TokenDigit,
  HintGroup,
  Hint,
  Link,
  LineBreak,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from '../../shared/Button';
import { product } from '../../../styles/theme/themes';
import { ThemeContext } from 'styled-components';

const TwoFactorAuthentication = ({
  isCheckout = false,
  userEmail = null,
  onTokenChange,
  onHandleSubmit,
  goBack,
  loading,
}) => {
  const themeContext = useContext(ThemeContext);
  const [ token2FADigits, setToken2FADigits ] = useState({
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: '',
    digit5: '',
    digit6: '',
  });
  const { t } = useTranslation('translations');

  const getToken2FA = () => {
    const { digit1, digit2, digit3, digit4, digit5, digit6 } = token2FADigits;
    return [ digit1, digit2, digit3, digit4, digit5, digit6 ].join('');
  };

  const setToken2FA = (digit, field) => {
    setToken2FADigits(prevState => ({
      ...prevState,
      [field]: digit,
    }));
  };

  const clearTokenDigits = () => {
    setToken2FADigits({
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: '',
      digit5: '',
      digit6: '',
    });
  };

  useEffect(() => {
    onTokenChange(getToken2FA());
  }, [ token2FADigits ]);

  const onKeyPressToken = (e, field) => {
    if (e.key === 'Backspace') {
      setToken2FA('', field);
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else if (e.key === 'Enter') {
      onHandleSubmit();
    }
    if (!/^[\d]*$/.test(e.key) && e.keyCode !== 86) {
      e.preventDefault();
    } else if (e.key >= 0 && e.key <= 9) {
      setToken2FA(e.key, field);
      if (e.target.nextSibling) e.target.nextSibling.focus();
      e.preventDefault();
    }
  };

  const onPasteToken = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text').split('');

    setToken2FA(pastedData[0] || '', 'digit1');
    setToken2FA(pastedData[1] || '', 'digit2');
    setToken2FA(pastedData[2] || '', 'digit3');
    setToken2FA(pastedData[3] || '', 'digit4');
    setToken2FA(pastedData[4] || '', 'digit5');
    setToken2FA(pastedData[5] || '', 'digit6');
  };

  const resendEmail = () => {
    clearTokenDigits();
    onHandleSubmit();
  };

  return (
    <Content isCheckout={isCheckout} product={product}>
      <Title className="title-login">
        {t('authentication.twoFactorAuthentication')}
      </Title>
      <Subtitle className="subtitle-login">
        {t('authentication.checkYourEmail')}
      </Subtitle>
      <Message dangerouslySetInnerHTML={{ __html: t('authentication.emailToken', { email: userEmail }) }} />
      <form onSubmit={onHandleSubmit} name="authentication">
        <TokenGroup>
          <TokenDigit
            autoFocus
            type="number"
            value={token2FADigits.digit1}
            onChange={event => setToken2FA(event.target.value, 'digit1')}
            onKeyDown={e => onKeyPressToken(e, 'digit1')}
            onPaste={onPasteToken}
          />
          <TokenDigit
            type="number"
            value={token2FADigits.digit2}
            onChange={event => setToken2FA(event.target.value, 'digit2')}
            onKeyDown={e => onKeyPressToken(e, 'digit2')}
            onPaste={onPasteToken}
          />
          <TokenDigit
            type="number"
            value={token2FADigits.digit3}
            onChange={event => setToken2FA(event.target.value, 'digit3')}
            onKeyDown={e => onKeyPressToken(e, 'digit3')}
            onPaste={onPasteToken}
          />
          <TokenDigit
            type="number"
            value={token2FADigits.digit4}
            onChange={event => setToken2FA(event.target.value, 'digit4')}
            onKeyDown={e => onKeyPressToken(e, 'digit4')}
            onPaste={onPasteToken}
          />
          <TokenDigit
            type="number"
            value={token2FADigits.digit5}
            onChange={event => setToken2FA(event.target.value, 'digit5')}
            onKeyDown={e => onKeyPressToken(e, 'digit5')}
            onPaste={onPasteToken}
          />
          <TokenDigit
            type="number"
            value={token2FADigits.digit6}
            onChange={event => setToken2FA(event.target.value, 'digit6')}
            onKeyDown={e => onKeyPressToken(e, 'digit6')}
            onPaste={onPasteToken}
          />
        </TokenGroup>
        <HintGroup>
          <Hint>{t('authentication.expirationTime')}</Hint>
          <Link onClick={resendEmail}>
            <span>{t('authentication.resendEmail')}</span>
          </Link>
        </HintGroup>
        <LineBreak />
        <Hint style={{ marginBottom: '56px' }}>
          {t('authentication.protectedAccount')}
        </Hint>
        <Button size="lg" loading={loading} btnType="submit" icon="ic-arrow-right">
          {t('global.continue')}
        </Button>
        <Button
          size="lg"
          action={goBack}
          variant="transparent"
          styleText={{ color: themeContext.isWL ? themeContext.color.neutral.low.pure : null }}
        >
          {t('text.back')}
        </Button>
      </form>
    </Content>
  );
};

export default TwoFactorAuthentication;
