import { types } from '../actions/types';

const initialState = {
  user: {
    id: 0,
    name: '',
    lastName: '',
    email: '',
    phone: '',
  },
  userAllData: {
    addressComplement: null,
    addressCountry: null,
    billingCountry: null,
    broker: null,
    cellPhone: null,
    city: null,
    commercialPhone: null,
    companyName: null,
    dateOfBirth: null,
    document: null,
    email: null,
    firstName: null,
    gender: null,
    homePhone: null,
    id: null,
    isNaturalPerson: null,
    isProfessional: null,
    language: null,
    lastName: null,
    methodologyAnalysis: null,
    methodologyStudy: null,
    neighborhood: null,
    number: null,
    operationTypes: [],
    postCode: null,
    profession: null,
    publicPlace: null,
    schooling: null,
    state: null,
    stateShort: null,
    traderExperience: null,
  },
};

const userReducer = (state = initialState, action) => {
  const { USER } = types;
  const { type, payload } = action;

  switch (type) {
    case USER.SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case USER.LOGOFF: {
      return {
        ...state,
        user: initialState.user,
      };
    }
    default:
      return state;
  }
};

const userAllDataReducer = (state = initialState, action) => {
  const { USER } = types;
  const { type, payload } = action;

  switch (type) {
    case USER.SUCCESSALL:
      return {
        ...state,
        userAllData: payload,
      };
    default:
      return state;
  }
};

export {
  userReducer,
  userAllDataReducer,
};
