import { types } from '../actions/types';

const initialState = {
  home: null,
};

const coursesReducer = (state = initialState, action) => {
  const { COURSES } = types;
  const { type, payload } = action;

  switch (type) {
    case COURSES.SET_HOME_COURSES: {
      payload.map((course) => {
        if (!course.arrBkgColor) {
          course.arrBkgColor = (course.strBackgroundColor || '').split(',');
        }
      });

      return {
        ...state,
        home: payload,
      };
    }
    default:
      return state;
  }
};

export default coursesReducer;
