const b3_dark = {
  title: 'b3_dark',
  isInvestMe: true,
  director: 'Rodrigo Paiva',
  name: 'Hub3',

  var: {
    showInHeader: false,
    navIcon: false,
    navItem: false,
    directorSignature: {
      top: '-50px',
      left: '-60px',
      width: '200px',
    },
    homeTabletMaxWidth: '642px',
  },

  homeInformation: {
    cardOne: 'homeInformation.cardOne',
    cardTwo: 'homeInformation.cardTwo',
    cardThree: 'homeInformation.cardThree',
  },

  header: {
    blogLink: '',
    blogName: '',
    helpName: '',
    helpLink: '',
  },

  aboutUs: {
    preface: 'preface',
    title: 'title',
    subtitle: 'subtitle',
  },

  knowTeam: {
    title: 'knowTeam.title',
    subtitle: 'knowTeam.subtitle',
  },

  imgs: {
    unavailableContent: 'url(\'/backgroundUnavailable.png\')',
    unavailableContentMobile: 'url(\'/bkgUnavailableMobileBottom.png\'), url(\'/bkgUnavailableMobileTop.png\')',
    register: 'url(\'/RegisterCover.svg\')',
    defaultPerfil: '/themes/investmeb3/default-perfil.png',
    directorSignature: '/director-signature.png',
    homeTablet: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/prancheta.webp',
  },

  breakpoints: {
    xs: '376px',
    sm: '426px',
    md: '769px',
    mdx: '900px',
    lg: '1025px',
    // xl: "1440px",
    // xxl: "1921px",
    xl: '1367px',
    xxl: '1440px',
    xxxl: '1921px',
  },

  box: {
    md: '780px',
    lg: '1366px',
  },

  font: {
    family: {
      site: 'Roboto',
      title: 'Roboto',
    },
    families: 'Roboto:ital,300,400,500,600,700',
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    size: {
      xxxs: '10px',
      xxs: '12px',
      xs: '14px',
      sm: '16px',
      md: '20px',
      lg: '24px',
      xl: '32px',
      xxl: '40px',
      xxxl: '48px',
    },
    custom: {
      size: {
        custom01: '18px',
      },
    },
  },

  border: {
    radius: {
      none: '0px',
      sm: '4px',
      md: '8px',
      lg: '20px',
      rounded: '100px',
      circular: '50%',
    },
  },

  color: {
    brand: {
      pure: '#30F3F3',
      dark: '#6863F5',
      darkest: '#0aa9a9',
      light: '#56f5f5',
    },
    gradient: {
      aboutUs: 'linear-gradient(98deg, #b1b9be 22%, #292929 82%)',
      banner: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 63.54%, #020c13 100%)',
      bannerMobile: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 48.44%, #020c13 100%)',
    },
    neutral: {
      low: {
        pure: '#000000',
        darkestplus: '#141A22',
        darkest: '#000000',
        dark: '#000000',
        medium: '#292929',
        light: '#2F3C52',
        lightest: '#b1b9be',
      },
      high: {
        pure: '#FFFFFF',
        lightest: '#BFBFBF',
        light: '#A0A0A0',
        medium: '#384B66',
        dark: '#5F707B',
        darkest: '#45545C',
      },
    },
    feedback: {
      success: '#30F3F3',
      warning: '#FEE60B',
      danger: '#EB5948',
      dangerLight: '#FF5A5A',
      dangerDark: '#AB1A31',
      message: '#a6b2ba',
    },
    background: {
      body: '#000000',
      imageInfoSection: '#000000',
      loader: '#000000',
      unavailableContent: '#000000',
      infoCards: '#292929',
      card: '#292929',
      checkout: '#292929',
      courseCard: '#000000',
      cardDivider: '#b1b9be',
      input: '#000000',
      inputSearch: '#000000',
      result: '#292929',
      resultHover: '#000000',
      tab: '#000000',
      subMenu: '#000000',
      modal: '#292929',
      footer: '#292929',
      header: 'rgba(11, 11, 11, 0.8)',
      headerItem: '#2F3C52',
      infoBar: '#292929',
      knowTeam: '#000000',
      bottomBar: '#2F3C52',
      cardImage: '#292929',
      nav: '#FFFFFF',
      textArea: '#292929',
      homeIndicator: '#868E96',
      menu: '#2F3C52',
      progressBar: '#292929',
      textInputDisabled: '#000000',
      textInput: '#000000',
      banner: '#020c13',
      courseLP: '#000000',
      parallaxCourseLP: '#000000',
      compareBlock: '#2F3C52',
      subscriptionContent: '#292929',
      inputSearchContainer: '#232326',
      inputSearchShortcut: '#000000',
      progressColor: '#000000',
      collapsible: '#292929',
      moduleIndex: '#000000',
      moduleIndexOpen: '#000000',
      buttonDark: '#000000',
      buttonDarkHover: '#000000',
    },
    mobile: {
      background: '#292929',
      title: '#FFFFFF',
      subtitle: '#FFFFFF',
      header: '#000000',
      couseModuleHeader: '#232326',
      card: '#000000',
      collapsible: '#A0A0A0',
      homeButton: '#FFFFFF',
      message: '#a6b2ba',
      inputSearch: '#232326',
    },
    // CUSTOM
    backgroundDegrade: 'linear-gradient(to bottom, rgba(73, 73, 73, 0), #1f1f1f 110%)',
    transparent: 'transparent',
    primary: '#30F3F3',
    secondary: '#FFFFFF',
    title: '#FFFFFF',
    subtitle: '#FFFFFF',
    moduleIndex: '#FFFFFF',
    subtitleCustom: '#a6b2ba',
    description: '#A0A0A0',
    price: '#FFFFFF',
    label: '#A0A0A0',
    text: '#384B66',
    textCustom: '#d4d4d4',
    secondaryText: '#909ba2',
    home: '#BFBFBF',
    header: '#BFBFBF',
    textLight: '#A0A0A0',
    cardHeader: '#FFFFFF',
    cardPrice: '#BFBFBF',
    cardText: '#30F3F3',
    cardTextCheckout: '#b1b9be',
    stepNumber: '#292929',
    infoLineTitle: '#BFBFBF',
    border: '#b1b9be',
    tabBorder: '#2F3C52',
    navBorder: '#2F3C52',
    headerBorder: '#232326',
    collapsibleBorder: 'transparent',
    textAreaBorder: '#292929',
    menuBorder: '#5F707B',
    textInputBorder: '#000000',
    cardBorder: '#FFFFFF',
    quiz: '#BFBFBF',
    quizDarkest: '#45545C',
    tabColor: '#FFFFFF',
    navColor: '#FFFFFF',
    tabBar: '#BFBFBF',
    darkSpan: '#909ba2',
    loginTitle: '#BFBFBF',
    message: '#BFBFBF',
    messageCustom: '#a6b2ba',
    formTitle: '#BFBFBF',
    navLink: '#A0A0A0',
    courseCard: '#a6b2ba',
    errorModal: '#a6b2ba',
    span: '#FFFFFF',
    stroke: '#000000',
    strokeLight: '#A0A0A0',
    collapsible: '#FFFFFF',
    comment: '#A0A0A0',
    unavailableContent: '#909ba2',
    courseProgress: '#A0A0A0',
    cardInfoCalendar: '#A0A0A0',
    courseLP: '#FFFFFF',
    infoSection: '#A0A0A0',
    continueText: '#A0A0A0',
    filterOptions: '#d4d4d4',
    classIC: '#b1b9be',
    headerTable: '#a6b2ba',
    darkBlur: 'rgba(11, 11, 11, 0.8)',
    inputSearch: '#232326',
    progressColor: '#384B66',
    progressText: 'white',
    liveNow: '#EB5948',
    blackStripe: '#000000',
    iconColor: '#A0A0A0',
    searchIconColor: '#FFFFFF',
    inputSearchShortcutColor: '#A0A0A0',
    inputColor: '#FFFFFF',
    placeHolderColor: '#A0A0A0',
    titleContainerText: '#A0A0A0',
    copyrightText: '#BFBFBF',
    iconColorMobile: '#FFFFFF',
    iconColorMobileCustom: '#FFFFFF',
    userHome: '#FFFFFF',
    nav: '#BFBFBF',
    buttonDarkIcon: '#A0A0A0',
  },
};

export default b3_dark;
