import { types } from '../actions/types';
import UserHelper from '../../helpers/UserHelper';

const initialState = {
  isLoggedIn: UserHelper.isLogged(),
};

const authReducer = (state = initialState, action) => {
  const { LOGIN } = types;
  const { type, payload } = action;

  switch (type) {
    case LOGIN.SUCCESS:
      return {
        ...state,
        isLoggedIn: payload,
      };
    case LOGIN.LOGOUT: {
      return {
        ...state,
        isLoggedIn: payload,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
