import React from 'react';
import { Button } from '../../components/shared/Button';
import { Container, TextContainer, MainText } from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UserHelper from '@/helpers/UserHelper';

function TraderWeekBar() {
  const { t } = useTranslation('translations');
  const { isLoggedIn } = useSelector(state => state.auth);
  const token = UserHelper.getBearerToken();
  const tokenParam = isLoggedIn ? `?token=${token}` : '';
  const url = `https://store.nelogica.com.br/academy/trader-week${tokenParam}`;

  return (
    <>
      <Container>
        <TextContainer>
          <img src="/assets/images/trader-week/main-trader-week.png" alt="Trader Week" />
          <MainText>
            <h3>{ t('traderWeek.mainTitle') }</h3>
            <h2>{ t('traderWeek.mainSubtitle') }</h2>
            <p>{ t('traderWeek.mainText') }</p>
          </MainText>
          <Button
            action={() => window.open(url, 'blank')}
            icon="ic-arrow"
            variant="trader-week"
          >
            { t('traderWeek.seeOffers') }
          </Button>
        </TextContainer>
        <div className="glow"></div>
      </Container>
    </>
  );
}

export default TraderWeekBar;
