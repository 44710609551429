import styled from 'styled-components';

export const Container = styled.div`
  position: sticky;
  background-color: #162D3BCC;
  margin: 0;
  z-index: 200;
  width: 100%;
  height: 30px;
  top: 64px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(12px);
    background-image: url('/assets/images/trader-week/bg-image.png');
    background-size: auto 100%;
    opacity: .15;
  }

  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    top: 52px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 62px;
    &:before {
      background-size: auto 50%;
    }
  }
`;

export const TextContainer = styled.div`
  position: relative;
  background: linear-gradient(270deg, rgba(41, 66, 165, 0) 0%, rgba(41, 66, 165, 0.8) 20%, rgba(23, 84, 174, 0.8) 50%, rgba(41, 66, 165, 0.8) 80%, rgba(41, 66, 165, 0) 100%);
  max-width: 605px;
  height: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 62px;
  }
`;

export const DiscountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  p {
    font-family: Roboto;
    font-size: 14.17px;
    font-weight: 500;
    line-height: 12px;
    color: #D8DDF2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    p {
      font-size: 11px;
      line-height: 11px;
      .primary-text {
        display: none;
      }
    }
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const OfferLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
  color: #FFFFFF;
  cursor: pointer;
  &:after {
    content: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.668141 1L4.66669 5L0.666687 9' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    text-decoration: underline;
    font-size: 12px;
    line-height: 12px;
    &:after {
      display: none;
    }
  }
`;

export const CloseIcon = styled.div`
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.6673 8.2735L15.7273 7.3335L12.0007 11.0602L8.27398 7.3335L7.33398 8.2735L11.0607 12.0002L7.33398 15.7268L8.27398 16.6668L12.0007 12.9402L15.7273 16.6668L16.6673 15.7268L12.9407 12.0002L16.6673 8.2735Z' fill='white'/%3E%3C/svg%3E%0A");
  position: absolute;
  right: 36px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    right: 12px;
  }
`;
