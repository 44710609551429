import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 500;
`;

export const Body = styled.div`
  margin-top: 26px;
  color: ${props => props.theme.color.neutral.high.medium};
  font-size: ${props => props.theme.font.size.xs};
`;
