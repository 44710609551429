const StringHelper = {
  onlyDigits(str) {
    const NON_DIGIT_PATTERN = /([^\d])+/gim;
    if (str) {
      return str.replace(NON_DIGIT_PATTERN, '');
    }
    return str;
  },
};

export default StringHelper;
