import COUNTRIES from '../../json/Countries.json';

export default class CountryHelper {
  static BRAZIL_ID = 31;

  static getCountries() {
    return COUNTRIES;
  }

  static getCountry(nCountryId) {
    const id = parseInt(nCountryId, 10);
    return COUNTRIES.find((country) => country.id === id);
  }

  static getCountryCode(nCountryId, codeType = 'abbr2') {
    let prop = codeType;

    if (![ 'abbr2', 'abbr3', 'ISOcode' ].includes(codeType)) {
      prop = 'abbr2';
    }

    const id = parseInt(nCountryId, 10);
    return (COUNTRIES.find((country) => country.id === id) || {})[prop];
  }

  static getCountryByCode(code, codeType = 'abbr2') {
    let prop = codeType;

    if (![ 'abbr2', 'abbr3', 'ISOcode' ].includes(codeType)) {
      prop = 'abbr2';
    }

    return COUNTRIES.find((country) => country[prop] === code);
  }
}
