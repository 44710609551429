const StorageHelper = {
  setToLocalStorage(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  getFromLocalStorage(key) {
    const value = window.localStorage.getItem(key);
    if (value || key === 'access_token') {
      try {
        return JSON.parse(value);
      } catch {
        return key === 'access_token' ? null : value;
      }
    }
  },
  removeFromLocalStorage(key) {
    window.localStorage.removeItem(key);
  },
};

export default StorageHelper;
