import React, { useCallback, useContext, useEffect, useState } from 'react';
import Container, {
  AlertContainer,
  ButtonLogin,
  CloseButton,
  Content,
  DivButton,
  LineWithMiddleLine,
  MessageErrorContainer,
  Title,
} from './styles';
import SvgIcon from '../../handlers/HandleSvg';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../shared/Button';
import Modal from '../shared/Modal';
import TextInput from '../shared/TextInput';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import { setIsLoggedIn } from '../../store/actions/auth';
import EnvHelper from '../../helpers/EnvHelper';
import UserHelper from '../../helpers/UserHelper';
import { setUserData } from '../../store/actions/user';
import { auth } from '../../modules/auth';
import { useFormik } from 'formik';
import { LoginSchema } from '../../validations/validationSchema';
import sanitizeHtml from 'sanitize-html';
import { ThemeContext } from 'styled-components';
import NLLogger from '@common/classes/nelogica/NLLogger';
import UserTrackingHelper from '../../helpers/UserTrackingHelper';
import { getNameByProduct, product } from '../../styles/theme/themes';
import { useResponsive } from '../../helpers/HooksHelper';
import RecaptchaHelper from '@common/classes/helpers/RecaptchaHelper';

const Login = ({
  isCheckout = false,
  actionToRegister = () => {},
  email = undefined,
  isModalLogin = false,
  isFreeCourse = false,
  isExpiredSession = false,
  courseData = {},
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn } = useSelector(state => state.auth);
  const {
    t,
    i18n: { language },
  } = useTranslation('translations');
  const [ errorLogin, setErrorLogin ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ bShowAuthentication, setShowAuthentication ] = useState(false);
  const [ srtUserEmail, setUserEmail ] = useState(null);
  const themeContext = useContext(ThemeContext);
  const [ isUserBlocked, setIsUserBlocked ] = useState(false);
  const [ unmounted, setUnmounted ] = useState(false);
  const nPlatformID = 4;
  const { isMobile } = useResponsive();
  const params = useParams();

  const logData = {
    browser: EnvHelper.browserDetect(),
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (isModalLogin) {
        return actionToRegister();
      }
      return isCheckout ? actionToRegister('resume') : history.push(`/${language}/home`);
    }
    return () => {
      setUnmounted(true);
    };
  }, []);

  const onSubmit = async (values) => {
    if (formik.isSubmitting && formik.isValid) {
      const token = await RecaptchaHelper.execute();
      if (!token) {
        NLLogger.doLog('ERROR', 'Token não recebido.', { ...logData });
        return;
      }

      try {
        setLoading(true);
        const resp = await auth.authenticate({
          username: values.userEmail,
          password: values.userPassword,
          token2FA: bShowAuthentication ? values.token2FA : '',
          strRecaptchaResponse: token,
          nPlatformID,
        });
        if (isFreeCourse) {
          history.push(`/${language}/courselp/${params.nClassId}?buy=true`);
        }
        dispatch(
          setUserData({
            id: resp.data.id,
            UUID: resp.data.UUID,
            name: resp.data.firstName,
            lastName: resp.data.lastName,
            email: resp.data.email,
            bUserHasCourses: resp.data.bUserHasCourses,
            phone: resp.data.cellPhone,
            billingCountry: resp.data.billingCountry,
          }),
        );
        dispatch(setIsLoggedIn(UserHelper.isLogged()));
        if (!isCheckout && !isModalLogin) {
          const { state = {} } = location;
          const { from = '' } = state;

          if (from) {
            history.push(from);
          } else if (resp.data.bUserHasCourses) {
            history.push(`/${language}/courses`);
          } else {
            history.push(`/${language}/home`);
          }
        }
        if (!unmounted) actionToRegister();
      } catch (error) {
        if (error.data?.action === '2fa_token_required') {
          setUserEmail(error.data?.email);
          setShowAuthentication(true);
          setErrorLogin(false);
        } else if (error.data?.code === 429) {
          setIsUserBlocked(true);

          if (isCheckout) {
            UserTrackingHelper.trackEvent({
              ...courseData,
              eventName: 'Checkout Failure',
              checkoutStep: 'checkout-error',
              modality: 'anual',
              currency: 'BRL',
              error: t('login.tooManyLoginRequests'),
              httpCode: 429,
              brokerName: product,
            });
          }
        } else {
          scrollTo({ top: 0, behavior: 'smooth' });
          NLLogger.doLog('ERROR', 'Erro genérico na execução do login do usuário.', {});
          setErrorLogin(true);

          if (isCheckout && error.data?.action !== '2fa_token_required') {
            UserTrackingHelper.trackEvent({
              ...courseData,
              eventName: 'Checkout Failure',
              checkoutStep: 'checkout-error',
              modality: 'anual',
              type: 'Produtos',
              currency: 'BRL',
              error: 'Erro genérico na execução do login do usuário.',
              httpCode: error.status,
              brokerName: product,
            });
          }
        }
      } finally {
        if (!unmounted) setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleSignUp = () => {
    if (isCheckout) {
      history.push(`/${language}/checkout/sign-up/${params.nClassId}`);
      actionToRegister('resume');
    } else {
      history.push(`/${language}/register`);
    }
  };

  const handleForgetPassword = () => {
    history.push(`/${language}/password-recovery`);
    actionToRegister();
  };

  const getWelcomeMessage = () => {
    if (isCheckout) {
      if (isFreeCourse) {
        return t('login.welcomeChekoutFree');
      }
      return t('login.welcomeChekout');
    }
    if (isExpiredSession) {
      return t('errors.loginAgain');
    }

    return t('login.welcome', { productName: getNameByProduct() });
  };

  const formik = useFormik({
    initialValues: {
      userEmail: email ? email : '',
      userPassword: '',
      token2FA: '',
    },
    validationSchema: LoginSchema,
    onSubmit,
  });

  const getFormTitle = () => {
    if (themeContext.isWL) return t('login.descriptionCheckoutWL', { productName: getNameByProduct() });

    return t('login.descriptionCheckout', { productName: getNameByProduct(true) });
  };

  const handleBlur = useCallback((e) => {
    formik.handleBlur(e);
  }, []);

  const handleChange = useCallback((e) => {
    formik.handleChange(e);
  }, []);

  const renderLoginContent = () => {
    return (
      <Content product={product}>
        <Title centerText={isCheckout} isCheckout={isCheckout} className="title-login">
          {getWelcomeMessage()}
        </Title>
        {isCheckout ? <span className="description-checkout" dangerouslySetInnerHTML={{ __html: sanitizeHtml(getFormTitle()) }}></span> : null}
        <form style={{ width: '100%' }} onSubmit={formik.handleSubmit} name="login">
          <TextInput
            autofocus
            disabled={loading}
            name="userEmail"
            label={t('login.username')}
            placeholder={t('login.userPlaceholder')}
            maxLength={50}
            onBlur={handleBlur}
            onChange={handleChange}
            value={formik.values.userEmail}
            isLogin
            inputStyle={{
              marginTop: '0px',
              background: isMobile ? (!EnvHelper.isWL() ? themeContext.color.neutral.low.medium : themeContext.color.neutral.low.dark) : null,
            }}
            labelStyle={{
              fontSize: '14px',
            }}
          />
          <TextInput
            disabled={loading}
            type="password"
            name="userPassword"
            label={t('login.password')}
            placeholder={t('login.passwordPlaceholder')}
            onChange={handleChange}
            value={formik.values.userPassword}
            isLogin
            inputStyle={{
              background: isMobile ? (!EnvHelper.isWL() ? themeContext.color.neutral.low.medium : themeContext.color.neutral.low.dark) : null,
            }}
            onBlur={handleBlur}
            passIconColor={themeContext.color.neutral.low.lightest}
            isPassword
          />
          <ButtonLogin onClick={() => handleForgetPassword()}>
            <SvgIcon name="ia-lock-ic" width="20px" height="20px" color={themeContext.isInterInvest ? themeContext.color.primary : themeContext.color.secondary} />
            <span>{t('login.forgetPassword')}</span>
          </ButtonLogin>
          <DivButton>
            <Button size="lg" loading={loading} btnType="submit" disabled={isUserBlocked}>
              {t('login.signIn')}
            </Button>
            {!isModalLogin && <LineWithMiddleLine>{t('login.or')}</LineWithMiddleLine>}
            {!isModalLogin && (
              <Button size="lg" action={handleSignUp} variant="secondary">
                {t('login.signUp')}
              </Button>
            )}
          </DivButton>
        </form>
      </Content>
    );
  };

  return (
    <Container isCheckout={isCheckout} isModalLogin={isModalLogin} isAuthentication={bShowAuthentication}>
      <AlertContainer style={!errorLogin || (bShowAuthentication && isCheckout) ? { display: 'none' } : {}}>
        <MessageErrorContainer>
          <SvgIcon name="ic-alert-circle-outline" width="20px" height="20px" />
          <span>{bShowAuthentication ? t('authentication.error') : isUserBlocked ? t('login.tooManyLoginRequests') : t('login.error')}</span>
        </MessageErrorContainer>
        <CloseButton onClick={() => setErrorLogin(false)}>
          <SvgIcon name="ic-close" width="25px" height="25px" />
        </CloseButton>
      </AlertContainer>
      { isCheckout || !bShowAuthentication ? renderLoginContent() : null }
      { bShowAuthentication && !isCheckout && (
        <TwoFactorAuthentication
          isCheckout={isCheckout}
          userEmail={srtUserEmail}
          onTokenChange={token => formik.setFieldValue('token2FA', token)}
          onHandleSubmit={formik.handleSubmit}
          goBack={() => setShowAuthentication(false)}
          loading={loading}
        />
      )}
      { bShowAuthentication && isCheckout && (
        <Modal height="initial" {...{ isShowing: true }} styleModal={{ padding: '0px' }}>
          <AlertContainer style={!errorLogin ? { display: 'none' } : {}}>
            <MessageErrorContainer>
              <SvgIcon name="ic-alert-circle-outline" width="20px" height="20px" />
              <span>{t('authentication.error')}</span>
            </MessageErrorContainer>
            <CloseButton onClick={() => setErrorLogin(false)}>
              <SvgIcon name="ic-close" width="25px" height="25px" />
            </CloseButton>
          </AlertContainer>
          <TwoFactorAuthentication
            isCheckout={isCheckout}
            userEmail={srtUserEmail}
            onTokenChange={token => formik.setFieldValue('token2FA', token)}
            onHandleSubmit={formik.handleSubmit}
            goBack={() => setShowAuthentication(false)}
            loading={loading}
          />
        </Modal>
      )}
    </Container>
  );
};
export default Login;
