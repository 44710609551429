import { types } from '../actions/types';

const initialState = {
  strRecaptchaResponse: '',
  isPlaylist: false,
  inContent: false,
  playlist: [],
  scrollBlock: true,
  showHiddenSubMenu: true,
  showHeader: true,
};

const appReducer = (state = initialState, action) => {
  const { APP } = types;
  const { type, payload } = action;

  switch (type) {
    case APP.SET_IS_PLAYLIST: {
      return {
        ...state,
        isPlaylist: payload,
      };
    }
    case APP.SET_PLAYLIST: {
      return {
        ...state,
        playlist: payload.map((item) => {
          return { ...item, type: 'playlist' };
        }),
      };
    }
    case APP.SET_IN_CONTENT: {
      return {
        ...state,
        inContent: payload,
      };
    }
    case APP.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollBlock: payload,
      };
    }
    case APP.SET_SHOW_HIDDEN_SUBMENU: {
      return {
        ...state,
        showHiddenSubMenu: payload,
      };
    }
    case APP.SET_SHOW_HEADER: {
      return {
        ...state,
        showHeader: payload,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
