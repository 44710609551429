function addElement(tagName, attributes, content) {
  const element = document.createElement(tagName);
  for (let key in attributes) {
    element.setAttribute(key, attributes[key]);
  }
  if (content) {
    element.innerHTML = content;
  }
  return element;
}

function addScriptsToHead() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_PRODUCT === 'genial') {
    const script1 = addElement('script', { async: true }, `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NBQCNKG');
    `);
    document.head.appendChild(script1);
  }
}

function addScriptsToBody() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_PRODUCT === 'investacademy') {
    const scriptGTM = addElement('script', { async: true }, `
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push(
          { 'gtm.start': new Date().getTime(), event: 'gtm.js' }
        ); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-WGGNFB8');
    `);
    document.body.appendChild(scriptGTM);

    const script2 = addElement('script', { async: true, src: 'https://www.googletagmanager.com/gtag/js?id=UA-235704208-1' });
    document.body.appendChild(script2);

    const script3 = addElement('script', { async: true }, `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'UA-235704208-1');
      gtag.async = true;
    `);
    document.body.appendChild(script3);

    const script4 = addElement('script', { async: true, src: 'https://www.googletagmanager.com/gtag/js?id=G-H51FLC7TGZ' });
    document.body.appendChild(script4);

    const script5 = addElement('script', { async: true }, `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-H51FLC7TGZ');
      gtag.async = true;
    `);
    document.body.appendChild(script5);

    const noscript = addElement('noscript');
    const iframe = addElement('iframe', { src: 'https://www.googletagmanager.com/ns.html?id=GTM-WGGNFB8', height: '0', width: '0', style: 'display:none;visibility:hidden;' });
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_PRODUCT === 'genial') {
    const script6 = addElement('script', { async: true }, `
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push(
          { 'gtm.start': new Date().getTime(), event: 'gtm.js' }
        ); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-NBQCNKG');
    `);
    document.body.appendChild(script6);

    const script7 = addElement('script', { async: true, src: 'https://www.googletagmanager.com/gtag/js?id=UA-235704208-1' });
    document.body.appendChild(script7);

    const script8 = addElement('script', { async: true }, `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'UA-235704208-1');
      gtag.async = true;
    `);
    document.body.appendChild(script8);

    const noscript2 = addElement('noscript');
    const iframe2 = addElement('iframe', { src: 'https://www.googletagmanager.com/ns.html?id=GTM-NBQCNKG', height: '0', width: '0', style: 'display:none;visibility:hidden;' });
    noscript2.appendChild(iframe2);
    document.body.appendChild(noscript2);
  }

  if (process.env.REACT_APP_PRODUCT === 'intereduca') {
    const script9 = addElement('script', {}, `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '793483200769438');
        fbq('track', 'PageView');
        fbq('trackCustom', 'Cadastrou TRVDEA');
    `);
    document.body.appendChild(script9);

    const noscript3 = addElement('noscript');
    const img = addElement('img', { height: '1', width: '1', style: 'display:none', src: 'https://www.facebook.com/tr?id=793483200769438&ev=PageView&noscript=1' });
    noscript3.appendChild(img);
    document.body.appendChild(noscript3);
  }

  if (process.env.REACT_APP_PRODUCT === 'investacademy') {
    const iframe3 = addElement('iframe', { id: 'whatsapp-iframe', width: '75', height: '50', title: 'whatsapp-iframe', style: 'border: 0; position: fixed; bottom: 140px; right: 20px; z-index: 1030;', src: 'https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=4px&number=555196818280' });
    document.body.appendChild(iframe3);
  }
  if (process.env.REACT_APP_PRODUCT === 'investacademy' || process.env.REACT_APP_PRODUCT === 'genial') {
    const script10 = addElement('script', { id: 'ze-loader', src: '/assets/js/zendesk-loader.js' });
    document.body.appendChild(script10);
  }

  const script11 = addElement('script', { type: 'text/javascript', async: true, src: 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/56775803-1f56-45f0-912c-40cb4d50f3e4-loader.js' });
  document.body.appendChild(script11);
}

const ExternalScriptsHelper = {
  addElementsDynamically() {
    addScriptsToHead();
    addScriptsToBody();
    document.dispatchEvent(new Event('loadFonts'));
  },
};

export default ExternalScriptsHelper;
