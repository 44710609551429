import { types } from './types';

export const setNotifications = (payload) => {
  const { NOTIFICATIONS } = types;

  return {
    type: NOTIFICATIONS.SET,
    payload,
  };
};

export const clearNotification = (payload) => {
  const { NOTIFICATIONS } = types;
  return {
    type: NOTIFICATIONS.CLEAR,
    payload,
  };
};

export const clearAllNotifications = () => {
  const { NOTIFICATIONS } = types;

  return {
    type: NOTIFICATIONS.CLEAR_ALL,
  };
};

export const setNoNewNotifications = () => {
  const { NOTIFICATIONS } = types;

  return {
    type: NOTIFICATIONS.SET_NO_NEW,
  };
};
