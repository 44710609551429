import styled, { css } from 'styled-components';
import EnvHelper from '../../helpers/EnvHelper';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.theme.color.background.inputSearchContainer};
  border-radius: ${props => props.theme.border.radius.sm};
  padding: 0 8px;
  width: 100%;
  max-width: 400px;
  position: relative;

  svg {
    cursor: pointer;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isInvestMe && css`
      position: static;
      background-color: ${props => props.theme.color.inputSearch};
      padding-right: 8px;
      border-radius: 6px;
    `};
  }
`;

export const Input = styled.input`
  background-color: ${props => props.theme.color.background.inputSearch};
  color: ${props => props.theme.color.title};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size.xs};
  border-radius: ${props => props.theme.border.radius.sm};
  height: 36px;
  width: 100%;

  ::placeholder {
    color: ${props => props.theme.color.neutral.high.medium};

    ${props => props.isInvestMe && css`
      color: ${props.theme.color.neutral.high.light};
    `}
  }
  :focus {
    outline: none;
  }

  ${props => props.isInvestMe && css`
    height: 32px;
    background-color: ${props => props.theme.color.inputSearch};
    line-height: 16px;
  `};
`;

export const ShortcutBadge = styled.div`
  background-color: ${props => props.theme.color.background.inputSearchShortcut};
  color: ${props => props.theme.color.inputSearchShortcutColor};
  padding: 4px;
  font-size: 10px;
  white-space: nowrap;
  border-radius: 4px;
`;

export const ResultLoading = styled.div`
  width: 100%;
  height: 290px;
`;

export const ResultContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 900;
  top: 42px;
  background-color: ${props => props.theme.color.background.result};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0 ${props => `${props.theme.border.radius.sm} ${props.theme.border.radius.sm}`};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    min-width: min(calc(100svw - 48px), 530px);
    right: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-width: unset;
    right: unset;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100vw;
    height: 100vh;
    max-height: calc(100vh - 48px); //header
    left: -55px;
    bottom: 0;
    right: 0;
    overflow-x:hidden;
    background-color: ${props => props.theme.color.background.input};

    ${(props) => {
      if (props.theme.isWL) {
        return `
          position: fixed;
          height: calc(100vh);
          border-radius: 0;
          top: 52px;
          left: 0;
        `;
      }
    }}

    ::-webkit-scrollbar {
      width: 0;
    }

    ${props => props.isInvestMe && css`
      left: 0;
      top: 53px;
    `};
  }
`;

export const ResultType = styled.span`
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.color.primary};
  line-height: 16px;
  margin-bottom: 2px;
  display: block;
`;

export const ResultImageContainer = styled.div`
  cursor: pointer;
  margin-right: 16px;
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 2px;
  position: relative;
  display: flex;
  ${(props) => {
    if (props.backgroundColor && props.haveImage) {
      return EnvHelper.isInvestMe() ? `background: linear-gradient(270deg, ${props.backgroundColor[0]} 5%, ${props.backgroundColor[1]} 100%)` : `background: linear-gradient(270deg, ${props.backgroundColor.split(',')[0]} 5%, ${props.backgroundColor.split(',')[1]} 100%)`;
    }
  }};
`;

export const ResultImage = styled.img`
  width: 100%;
  min-width: 56px;
  width: 100%;
  height: ${props => props.zIndex === 2 ? '50%' : '100%'};
  border-radius: 2px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${props => props.zIndex};
`;

export const ResultSpan = styled.span`
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.color.subtitle};
  line-height: 16px;
`;

export const ResultItem = styled.div`
  color: ${props => props.theme.color.neutral.high.pure};
  font-size: ${props => props.theme.font.size.xs};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => props.resultOpen ? '8px 12px' : '0px'};
  padding: ${props => props.footer_text ? '0px 12px' : '8px 12px'};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: ${props => props.footer_text ? '16px' : '8px 16px'};
  }

  &:hover {
    ${ResultSpan} {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${(props) => {
    if (props.link) {
      return `
        &.active, &:hover {
          background-color: ${props.theme.color.background.resultHover};
          cursor: pointer;
        }
      `;
    }
  }}
`;

export const DarkSpan = styled.span`
  color: ${props => props.theme.color.darkSpan};
  font-size: ${props => props.theme.font.size.xs};
  padding: ${props => props.resultOpen ? '8px 12px' : '0px'};
  font-weight: ${props => props.theme.font.weight.regular};
`;

export const CancelIcon = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

export const ContainerTab = styled.aside`
  position: relative;
  width: 100%;
  ul {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    ul {
      justify-content: center;
      align-items: center;
    }
  }

  &.invest-me-nav {
    padding-top: 14px;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    nav {
      padding-left: 16px;
      ul {
        justify-content: flex-start;
      }
    }
  }
`;

export const TabFilter = styled.div`
  list-style: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  &:focus-visible {
    outline: none;
  }
  &:last-child {
    margin-right: initial;
  }
  a {
    transition: .5s all;
    &:active {
      transform: scale(.95);
    }
    &:focus-visible {
      outline: none;
    }
    width: fit-content;
    border-radius: 100px;
    border: 1px solid;
    border-color: ${props => props.theme.color.neutral.low.medium};
    padding: 6px 16px;
    text-decoration: none;
    color: ${props => props.theme.color.neutral.high.light};
    font-weight: ${props => props.theme.font.weight.regular};
    font-size: ${props => props.theme.font.size.xs};
    line-height: 16px;
  }
  .active {
    ${(props) => {
      if (props.theme.title === 'b3_dark') {
        return `
        border-color: ${props.theme.color.neutral.high.pure};
        background-color: ${props.theme.color.neutral.high.pure};
        color: ${props.theme.color.neutral.low.pure};
        `;
      } else {
        return `
        border-color: ${props.theme.color.primary};
        background-color: ${props.theme.color.primary};
        color: ${props.theme.color.neutral.low.pure};
        `;
      }
    }}
  }
`;

export const TextContainer = styled.div`
  display: grid;
  font-size: small;
`;
