import { types } from './types';

export const setLocation = (newValue) => {
  const { LOCATION } = types;

  return {
    type: LOCATION.SUCCESS,
    payload: newValue,
  };
};
